import React from 'react'
import ReactDOM from 'react-dom'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'

import App from './App.jsx'
import reportWebVitals from './reportWebVitals'
import './theme/style.css'
import { ThemeProvider } from 'styled-components'
import theme from './theme/theme'
import { en, de, fr } from './i18n'
import { sentry } from './config'

sentry()

i18next
  .use(initReactI18next)
  .init({
    lng: 'de',
    fallbackLng: ['de', 'en', 'fr'],
    debug: false,
    keySeparator: ':',
    resources: { en, de, fr },
  })
  .then(() => {})
  .catch((err) => console.error(err))

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
