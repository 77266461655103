import * as THREE from 'three'
import React, { useState, useMemo } from 'react'
import GroundHelper from './GroundHelper'

const Terrain = ({ groundMatrix, overlay }) => {
  const [overlayTexture, setOverlayTexture] = useState()
  const [topGeometry, setTopGeometry] = useState()
  const [bottomGeometry, setBottomGeometry] = useState()
  const [gridGeometry, setGridGeometry] = useState()
  const [overlayGeometry, setOverlayGeometry] = useState()
  const [opacity, setOpacity] = useState(0.7)
  const [bgColor, setBgColor] = useState('#CDCDCD')

  useMemo(() => {
    if (groundMatrix?.length > 1) {
      const helper = new GroundHelper(groundMatrix)
      const { topGeometry: tg, bottomGeometry: bg } = helper.getGeometry()
      setTopGeometry(tg)
      setBottomGeometry(bg)
      setGridGeometry(helper.getGridGeometry())
      setOverlayGeometry(helper.getOverlayGeometry())
    }
  }, [groundMatrix])

  useMemo(() => {
    if (!overlay) return
    const texture =
      typeof overlay === 'string'
        ? new THREE.TextureLoader().load(overlay)
        : overlay
    setOpacity(typeof overlay === 'string' ? 1 : 1)
    setBgColor(typeof overlay === 'string' ? '#BFBFBF' : '#FFFFFF')
    texture.center.set(0.5, 0.5)
    texture.rotation = Math.PI
    setOverlayTexture(texture)
  }, [overlay])

  return (
    <group>
      {overlay && (
        <mesh
          key='groundOverlay'
          name='groundOverlay'
          renderOrder={4}
          receiveShadow
          geometry={overlayGeometry}
          onUpdate={(self) => self.geometry.computeFaceNormals()}
        >
          <meshPhongMaterial
            attach='material'
            color={bgColor}
            transparent
            opacity={opacity}
            side={THREE.BackSide}
            // clippingPlanes: [localPlane]
            onUpdate={(self) => (self.needsUpdate = true)}
            clipShadows={true}
            map={overlayTexture}
          />
        </mesh>
      )}
      <mesh
        key='groundTop'
        name='groundTop'
        renderOrder={3}
        receiveShadow
        geometry={topGeometry}
        onUpdate={(self) => self.geometry.computeFaceNormals()}
      >
        <meshLambertMaterial
          attach='material'
          color={'#EAEAEA'}
          transparent
          opacity={0.6}
          side={THREE.DoubleSide}
          // clippingPlanes: [neighbourhoodPlane]
          onUpdate={(self) => (self.needsUpdate = true)}
        />
      </mesh>
      <mesh
        key='groundBottom'
        name='groundBottom'
        renderOrder={3}
        geometry={bottomGeometry}
        onUpdate={(self) => self.geometry.computeFaceNormals()}
      >
        <meshPhongMaterial
          attach='material'
          color='#EAEAEA'
          transparent
          opacity={0.6}
          side={THREE.DoubleSide}
          // clippingPlanes: [neighbourhoodPlane],
          onUpdate={(self) => (self.needsUpdate = true)}
        />
      </mesh>
      {!overlay && (
        <lineSegments
          key='groundGrid'
          name='groundGrid'
          geometry={gridGeometry}
        >
          <lineBasicMaterial
            color='#969696'
            transparent
            opacity={1}
            // clippingPlanes: [localPlane],
            clipShadows
          />
        </lineSegments>
      )}
    </group>
  )
}

export default Terrain