import React, { useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { Canvas, extend, useThree, useFrame } from '@react-three/fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import SunLight from './SunLight'
import Terrain from './Terrain'
import Neighbours from './Neighbours'

extend({ OrbitControls })

const ControlsWrapper = ({ orbitRef }) => {
  const {
    camera,
    gl: { domElement },
  } = useThree()
  return (
    <orbitControls
      ref={orbitRef}
      args={[camera, domElement]}
      enableKeys={false}
      minDistance={40}
      maxDistance={1000}
    />
  )
}

/* Automatic camera panning logic for video purposes */
// function degreesToRadians(degrees) {
//   let pi = Math.PI;
//   return degrees * (pi/180);
// }
// function getPosition(t) {
//   const x = Math.cos(t);
//   const y = Math.sin(t);
//
//   return { x, y }
// }
/* End of camera panning logic */

const FrameLimiter = ({ fps }) => {
  const [clock] = useState(new THREE.Clock())
  /* Automatic camera panning logic for video purposes */
  // const { camera } = useThree();
  // let t = 0;
  /* End of camera panning logic */

  useFrame((state) => {
    state.ready = false
    const timeUntilNextFrame = 1000 / fps - clock.getDelta()

    setTimeout(() => {
      state.ready = true
      if (typeof state.invalidate !== 'undefined') state.invalidate()

      /* Automatic camera panning for video purposes */
      // t += 360 / fps / 40;
      // const tRad = degreesToRadians(t % 360);
      // const XY = getPosition(tRad);
      // camera.position.x = XY.x * 350;
      // camera.position.y = XY.y * 350;
      // camera.lookAt(new THREE.Vector3(0, 0, 0));
      /* End of camera panning logic */

    }, Math.max(0, timeUntilNextFrame))
  })

  return <></>
}

const CityView = (props) => {
  const orbit = useRef()
  const canvasRef = useRef(null)

  const [canvasAspectRatio, setCanvasAspectRatio] = useState(1.77);

  const {
    marker,
    groundMatrix,
    midpointElevation,
    overlay,
    topoBuildings
  } = props

  const clippingPlanes = [
    new THREE.Plane(new THREE.Vector3(0, 1, 0), 100.1), // south
    new THREE.Plane(new THREE.Vector3(0, -1, 0), 100.1), // north
    new THREE.Plane(new THREE.Vector3(-1, 0, 0), 100.1), // east
    new THREE.Plane(new THREE.Vector3(1, 0, 0), 100.1) // west
  ]

  function setCanvasSize() {
    const rect = canvasRef?.current?.getBoundingClientRect();
    const height = rect?.height;
    const width = rect?.width;

    if (width !== undefined && height !== undefined) {
      setCanvasAspectRatio(width / height);
    }
  }

  useEffect(() => {
    window.onresize = setCanvasSize;
    setCanvasSize();
  }, [])

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        cursor: 'auto',
        height: '100%',
        backgroundColor: '#1E2528'
      }}
      data-cy='3d-view'
      id={props.rendererContainerId}
      ref={canvasRef}
    >
      <Canvas
        shadowMap
        colorManagement={false}
        dpr={window.devicePixelRatio || 2}
        gl={{
          antialias: true,
          preserveDrawingBuffer: true,
        }}
        onCreated={({ gl }) => {
          gl.localClippingEnabled = true
          gl.clippingPlanes = clippingPlanes
          gl.shadowMap.type = THREE.PCFSoftShadowMap
          gl.shadowMap.enabled = true
          gl.toneMapping = THREE.NoToneMapping
        }}
        camera={{
          position: [390, -200, 170],
          fov: 36,
          near: 0.25,
          far: 10000,
          up: [0, 0, 1],
          name: 'Camera',
          aspect: canvasAspectRatio
        }}
      >
        <color attach='background' args={['#030b0f']} />
        <FrameLimiter fps={30} />
        <ambientLight color={'#FFFFFF'} intensity={0.4} />
        <hemisphereLight
          skyColor={'#FFFFFF'}
          groundColor={'#FFFFFF'}
          intensity={0.2}
        />
        <SunLight center={marker} date={new Date('July 15, 2021 13:00:00')} />
        <ControlsWrapper orbitRef={orbit} />
        <Terrain
          groundMatrix={groundMatrix}
          overlay={overlay}
        />
        <Neighbours
          buildings={topoBuildings}
          midpointElevation={midpointElevation}
          marker={marker}
        />
      </Canvas>
    </div>
  )
}

export default CityView
