import { SvgIcon } from '@material-ui/core'

const Longitude = props => (
  <SvgIcon
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    {...props}
  >
    <path
      d="M0 9.5A9.5 9.5 0 019.5 0 9.5 9.5 0 0119 9.5 9.5 9.5 0 019.5 19 9.5 9.5 0 010 9.5zm16.636 5.298H2.364A8.875 8.875 0 009.5 18.387a8.875 8.875 0 007.136-3.589zm1.746-5H.618a8.845 8.845 0 001.339 4.404h15.086a8.845 8.845 0 001.339-4.404zm-1.339-5H1.957A8.845 8.845 0 00.618 9.202h17.764a8.845 8.845 0 00-1.339-4.404zm-.407-.596A8.875 8.875 0 009.5.613a8.875 8.875 0 00-7.136 3.589h14.272z"
    />
  </SvgIcon>
)

export default Longitude
