import styled, { css } from 'styled-components'
import { Fab } from '@material-ui/core'
import theme, { shadows } from '../../theme/theme'

const StyledFab = styled(Fab)`
  &.MuiFab-root {
    &:hover {
      box-shadow: ${shadows.medium};
    }
  }

  &.MuiFab-sizeSmall {
    width: 32px;
    height: 32px;
    min-height: 32px;

    svg {
      width: 15px;
      height: 15px;
    }
  }

  ${({ outlined }) =>
    outlined &&
    css`
      background-color: ${theme.palette.grey[50]};
      border: 1px solid ${theme.palette.grey[200]};
      color: ${theme.palette.grey[600]};

      svg {
        fill: ${theme.palette.grey[600]};
      }

      &:hover {
        background-color: ${theme.palette.grey[50]};
        border: 1px solid ${theme.palette.grey[300]};
      }

      &.Mui-disabled {
        opacity: 0.4;
        background-color: ${theme.palette.grey[50]};
      }
    `}

  ${({ theme, active }) =>
    active &&
    css`
      border: 1px solid ${theme.palette.primary.main};
      box-shadow: ${shadows.medium};

      &:hover {
        border: 1px solid ${theme.palette.primary.main};
        background-color: ${theme.palette.grey[100]};
      }
    `}
`

export default StyledFab
