import styled, { css } from 'styled-components'
import { Avatar } from '@material-ui/core'

const StyledAppBar = styled.div`
  position: relative;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 40px;
  padding: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(2)}px`};
  background-color: ${({ theme }) => theme.palette.common.black};

  ${({ centerLogo, theme }) => centerLogo && css`
    justify-content: center;

    ${theme.breakpoints.up('md')} {
      justify-content: space-between;
    }
  `}
`

const Left = styled.div``

const Center = styled.div``

const Right = styled.div``

const LogoLink = styled.a`
  display: flex;
`

const Logo = styled.img`
  width: 90px;
`

const StyledAvatar = styled(Avatar)`
  width: 30px;
  height: 30px;
  font-size: 16px;
`

export { StyledAppBar, Left, Center, Right, LogoLink, Logo, StyledAvatar }
