import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@material-ui/core'
import {
  MapRouteContainer,
  MapTools,
  MapWrapper,
  ContinueWrapper,
  MapContinueButton,
  SwissTopo,
} from '../components/Map'
import Typography from '../components/Typography'
import { useWhichMapType } from '../hooks/useWhichMapType'
import TagManager from 'react-gtm-module'

const LocationInfo = () => {
  const [t] = useTranslation()
  const { municipality, canton, address } = useSelector((state) => state.map.location)
  return (
    <>
      <Grid item xs={12}>
        <Box textAlign='center' mb={2}>
          <Typography color='textPrimary' variant='h4' component='h1' fontWeight={500}>
            {address || t('mapRoute:plotSelected')}
          </Typography>
        </Box>
      </Grid>
      {(municipality || canton) && (
        <Box display='flex' justifyContent='center' width='100%' mt={-1} mb={2}>
          <Typography color='textSecondary' variant='caption'>
            {[municipality, canton].join(', ').replace(/,\s*$/, '')}
          </Typography>
        </Box>
      )}
    </>
  )
}

const MapRoute = ({ children }) => {
  const [t] = useTranslation()
  const history = useHistory()
  const [mapType] = useWhichMapType()
  const { lat, lng } = useSelector((state) => state.map.location)

  return (
    <MapRouteContainer>
      <MapTools />
      <MapWrapper>
        <SwissTopo href='https://www.swisstopo.admin.ch/' target='_blank' underline="none" mapType={mapType}>© swisstopo</SwissTopo>
        {(lat && lng) && (
          <ContinueWrapper mapType={mapType}>
            <Grid container>
              <LocationInfo />
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center'>
                  <MapContinueButton
                    onClick={() => {
                      TagManager.dataLayer({
                        dataLayer: {
                          event: 'plot_selected'
                        },
                      });
                      history.push('/site')
                    }}
                    size='large'
                    color='primary'
                    variant='contained'
                    disabled={!lat || !lng}
                  >
                    {t('mapRoute:download')}
                  </MapContinueButton>
                </Box>
              </Grid>
            </Grid>
          </ContinueWrapper>
        )}
        {children}
      </MapWrapper>
    </MapRouteContainer>
  )
}

export default MapRoute
