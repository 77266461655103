import { createTheme } from '@material-ui/core/styles'

export const dangers = {
  minor: '#F6BC41',
  medium: '#ED6521',
  serious: '#E40E00',
}

export const colors = {
  common: {
    black: '#030B0E',
    white: '#FBFBFB',
  },
  type: 'light',
  primary: {
    main: '#1FC676',
    light: '#8FE2BA',
    dark: '#18995B',
    contrastText: '#F9F9F9',
  },
  secondary: {
    main: '#030B0E',
    light: '#A7AAAB',
    dark: '#3A4042',
    contrastText: '#F9F9F9',
  },
  success: {
    main: '#1FC676',
    light: '#8FE2BA',
    dark: '#18995B',
    contrastText: '#F9F9F9',
  },
  info: {
    main: '#4200FF',
    light: '#7747FF',
    dark: '#3400C7',
    contrastText: '#F9F9F9',
  },
  warning: {
    main: dangers.minor,
    light: '#F7C967',
    dark: '#AC832D',
    contrastText: '#030B0E',
  },
  danger: dangers,
  error: {
    main: dangers.serious,
    light: '#E93E33',
    dark: '#9F0900',
    contrastText: '#F9F9F9',
  },
  grey: {
    50: '#FBFBFB',
    100: '#F9F9F9',
    200: '#DEDFDF',
    300: '#C2C4C5',
    400: '#A7AAAB',
    500: '#8C8F91',
    600: '#707576',
    700: '#555A5C',
    800: '#3A4042',
    900: '#1E2528',
    A100: '#DEDFDF',
    A200: '#A7AAAB',
    A400: '#1E2528',
    A700: '#555A5C',
  },
  divider: 'rgba(105,105,105,0.1)',
  text: {
    primary: 'rgba(3, 11, 14, 1)',
    secondary: 'rgba(3, 11, 14, 0.8)',
    disabled: 'rgba(3, 11, 14, 0.6)',
    hint: 'rgba(3, 11, 14, 0.4)',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  background: {
    paper: '#FFFFFF',
    default: '#FBFBFB',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
}

// const shadows = {
//   small:
//     '0 20px 50px -5px rgba(144, 144, 181, 0.3), 0 5x 10px -15px rgba(0, 0, 0, 0.3), 0 5px 5px 0 rgba(144, 144, 181, 0.1)',
//   medium:
//     '0 5px 40px -5px rgba(144, 144, 181, 0.3), 0 5px 10px -15px rgba(0, 0, 0, 0.3), 0 5px 5px 0 rgba(144, 144, 181, 0.1)',
//   large:
//     '0 40px 100px -10px rgba(144, 144, 181, 0.3), 0 10px 20px -30px rgba(0, 0, 0, 0.3), 0 10px 10px 0 rgba(144, 144, 181, 0.1)',
// }

export const shadows = {
  small: '0px 0px 4px rgba(85, 90, 92, 0.20);',
  medium: '0px 0px 6px rgba(85, 90, 92, 0.15)',
  large: '0px 0px 8px rgba(85, 90, 92, 0.10);',
}

export const transitions = {
  easing: {
    easeInOut: 'cubic-bezier(0.42, 0, 0.58, 1)',
    easeOut: 'cubic-bezier(0, 0, 0.58, 1)',
    easeIn: 'cubic-bezier(0.42, 0, 1, 1)',
    sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
  },
  duration: {
    shortest: 150,
    shorter: 200,
    short: 250,
    standard: 300,
    complex: 375,
    enteringScreen: 225,
    leavingScreen: 195,
  },
}

const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  direction: 'ltr',
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 48,
      },
      '@media (min-width:600px)': {
        minHeight: 64,
      },
    },
  },
  overrides: {
    MuiCheckbox: {
      root: {
        color: colors.grey[600],
      },
    },
    MuiButton: {
      root: {
        padding: '5px 30px',
        borderRadius: 45,
        textTransform: 'none',
      },
      sizeLarge: {
        padding: '8px 40px 10px',
      },
      containedPrimary: {
        '&:hover': {
          boxShadow: `${shadows.medium}`,
        },
      },
      containedSecondary: {
        color: colors.secondary.dark,
        backgroundColor: colors.grey[50],
        border: `1px solid ${colors.grey[200]}`,
        '&:hover': {
          color: colors.secondary.dark,
          backgroundColor: colors.grey[200],
          border: `1px solid ${colors.grey[300]}`,
          boxShadow: shadows.medium,
        },
      },
      outlined: {
        padding: '5px 30px',
      },
    },
    MuiFab: {
      secondary: {
        color: colors.secondary.dark,
        backgroundColor: colors.grey[50],
        border: `1px solid ${colors.grey[200]}`,
        '&:hover': {
          color: colors.secondary.dark,
          backgroundColor: colors.grey[200],
          border: `1px solid ${colors.grey[300]}`,
          boxShadow: shadows.medium,
        },
      },
      outlined: {
        padding: '5px 30px',
      },
    },
    MuiTabs: {
      root: {
        minHeight: 0,
      },
    },
    MuiTab: {
      root: {
        '@media (min-width: 0px)': {
          marginRight: 24,
          padding: '5px 0',
          minWidth: 0,
          height: 'auto',
          minHeight: 0,
          textTransform: 'initial',
          transition: `color 200ms ${transitions.easing.easeInOut}`,
          '&:hover': {
            '&:not($selected)': {
              color: colors.grey[700],
            },
          },
        },
      },
    },
  },
  palette: colors,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  shadow: shadows,
  shadows: [
    'none',
    'none', // '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.15)',
    'none', // '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.15)',
    'none', // '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.15)',
    'none', // '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.15)',
    'none', // '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.15)',
    'none', // '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.15)',
    'none', // '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.15)',
    'none', // '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.15)',
    'none', // '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.15)',
    'none', // '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.15)',
    'none', // '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.15)',
    'none', // '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.15)',
    'none', // '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.15)',
    'none', // '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.15)',
    'none', // '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.15)',
    'none', // '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.15)',
    'none', // '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.15)',
    'none', // '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.15)',
    'none', // '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.15)',
    'none', // '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.15)',
    'none', // '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.15)',
    'none', // '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.15)',
    'none', // '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.15)',
    'none', // '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.15)',
  ],
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Monument Grotesk, sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: 'Monument Grotesk, sans-serif',
      fontWeight: 400,
      fontSize: 62,
      lineHeight: 1.35,
    },
    h2: {
      fontFamily: 'Monument Grotesk, sans-serif',
      fontWeight: 400,
      fontSize: 42,
      lineHeight: 1.35,
    },
    h3: {
      fontFamily: 'Monument Grotesk, sans-serif',
      fontWeight: 400,
      fontSize: 24,
      lineHeight: 1.35,
    },
    h4: {
      fontFamily: 'Monument Grotesk, sans-serif',
      fontWeight: 400,
      fontSize: 20,
      lineHeight: 1.35,
    },
    h5: {
      fontFamily: 'Monument Grotesk, sans-serif',
      fontWeight: 400,
      fontSize: 18,
      lineHeight: 1.35,
    },
    h6: {
      fontFamily: 'Monument Grotesk, sans-serif',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.35,
    },
    subtitle1: {
      fontFamily: 'Monument Grotesk, sans-serif',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.35,
    },
    subtitle2: {
      fontFamily: 'Monument Grotesk, sans-serif',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1.35,
    },
    body1: {
      fontFamily: 'Monument Grotesk, sans-serif',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.6,
    },
    body2: {
      fontFamily: 'Monument Grotesk, sans-serif',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: 1.6,
    },
    body3: {
      fontFamily: 'Monument Grotesk, sans-serif',
      fontWeight: 400,
      fontSize: 10,
      lineHeight: 1.6,
    },
    button: {
      fontFamily: 'Monument Grotesk, sans-serif',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1.35,
    },
    caption: {
      fontFamily: 'Monument Grotesk, sans-serif',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 1.6,
    },
    overline: {
      fontFamily: 'Monument Grotesk, sans-serif',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 1.6,
      textTransform: 'uppercase',
    },
  },
  shape: {
    borderRadius: 4,
  },
  transitions: transitions,
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
})

export default theme
