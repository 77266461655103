import React, { useState, useMemo } from 'react'
import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import proj4 from 'proj4'
import { wgsToLocal } from './coordinateHelper'

const wgsProj = '+proj=longlat +datum=WGS84 +no_defs'
const lv =
  '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs'

const passiveMaterial = new THREE.MeshPhongMaterial({
  side: THREE.DoubleSide,
  color: 0xffffff,
  transparent: true,
})

const Building = ({ building, midpointElevation, marker}) => {
  const [object, setObject] = useState()
  const id = building.properties.uuid.replace('{', '').replace('}', '')
  const fileName = `${process.env.REACT_APP_BUILDING_OBJ_URL}/${id}.obj`

  useMemo(
    () =>
      new OBJLoader().load(fileName, (obj) => {
        obj.children.forEach((child) => {
          const vertices = child.geometry.attributes.position.array
          for (let i = 0; i < vertices.length; i += 3) {
            const [x1, y1] = [vertices[i], vertices[i + 1]]
            const wgs = proj4(lv, wgsProj, [x1, y1])
            const loc = wgsToLocal([{ lng: wgs[0], lat: wgs[1] }], marker)[0]
            const [x, y] = loc
            vertices[i] = x
            vertices[i + 1] = y
            vertices[i + 2] -= midpointElevation
          }
          child.material = passiveMaterial
          child.material.opacity = 1
          // child.material.needsUpdate = true;
          child.geometry.attributes.position.needsUpdate = true
          child.castShadow = true
          child.receiveShadow = true
          child.matrixAutoUpdate = false
        })
        obj.name = id
        obj.userData = { geometry: building.geometry }
        setObject(obj)
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileName],
  )

  return (
    <group>
      {object ? <primitive object={object} visible={true} /> : null}
    </group>
  )
}

const Buildings = ({ buildings, midpointElevation, marker }) => {
  return !marker
    ? null
    : buildings.map((b) => (
        <Building
          key={b.properties.uuid}
          building={b}
          marker={marker}
          midpointElevation={midpointElevation}
        />
      ))
}

export default Buildings
