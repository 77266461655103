import { StyledTabs, StyledTab, StyledBox } from './style'

const Tabs = (props) => {
  const {
    indicatorColor = 'primary',
    textColor = 'secondary',
    size = 'medium',
    children,
    className,
    classes,
    orientation,
    ...otherProps
  } = props

  return (
    <StyledTabs
      indicatorColor={indicatorColor}
      textColor={textColor}
      size={size}
      className={className}
      orientation={orientation}
      {...otherProps}
    >
      {children}
    </StyledTabs>
  )
}

const Tab = (props) => {
  const { children, ...otherProps } = props

  return <StyledTab {...otherProps}>{children}</StyledTab>
}

const TabPanel = (props) => {
  const { children, value, index, ...otherProps } = props

  return (
    <StyledBox
      pt={2}
      role='tab-panel'
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...otherProps}
    >
      {value === index && children}
    </StyledBox>
  )
}

export { Tabs, Tab, TabPanel }
