import styled  from 'styled-components'
import { Tabs, Tab, Box } from '@material-ui/core'

const StyledTab = styled(Tab)``

const handleMargin = (size, theme) => {
  switch (size) {
    case 'small':
      return `${theme.spacing(2)}px`
    case 'medium':
      return `${theme.spacing(3)}px`
    default:
      return `${theme.spacing(3)}px`
  }
}

const handleFontSize = (size) => {
  switch (size) {
    case 'small':
      return '12px'
    case 'medium':
      return '14px'
    default:
      return '16px'
  }
}

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    right: auto;
    left: 0;
  }

  ${StyledTab} {
    margin-right: ${({ size, theme }) => handleMargin(size, theme)};
    font-size: ${({ size }) => handleFontSize(size)};
    
    &:last-child {
      margin-right: 0
    }
  }
`

const StyledBox = styled(Box)`
  padding-top: ${({ theme }) => theme.spacing(1)}px;
`

export { StyledTabs, StyledTab, StyledBox }
