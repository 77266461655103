import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import {
  MenuButton,
  MenuToggle,
  MenuSpan,
  StyledMenu,
  StyledMenuItem,
  StyledRouterLink,
  StyledLink,
  StyledLinkIcon
} from './style'
import Separator from '../Separator'

const NavigationMenu = () => {
  const { t, i18n } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  }

  return (
    <Box>
      <MenuButton
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
        open={Boolean(anchorEl)}
      >
        <MenuToggle>
          <MenuSpan />
          <MenuSpan />
          <MenuSpan />
          <MenuSpan />
        </MenuToggle>
      </MenuButton>
      <StyledMenu
        id="language-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={handleClose}
        onClose={handleClose}
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {i18n.languages.map(language => (
          <StyledMenuItem onClick={() => changeLanguage(language)} activeLanguage={i18n.language === language} key={`language-item-${language}`}>
            {t(`languages:${language}`)}
          </StyledMenuItem>
        ))}
        <Separator />
        <StyledRouterLink to="/map/2d">
          <StyledMenuItem>
            {t('pages:explore')}
          </StyledMenuItem>
        </StyledRouterLink>
        <StyledRouterLink to="/faq">
          <StyledMenuItem>
            {t('pages:faq')}
          </StyledMenuItem>
        </StyledRouterLink>
        <StyledRouterLink to="/impressum">
          <StyledMenuItem>
            Impressum
          </StyledMenuItem>
        </StyledRouterLink>
        <Separator />
        <StyledLink href={`https://amenti.ch/${i18n.language}`} underline="none" color="inherit" target="_blank">
          <StyledMenuItem>
            <Box mr={0.75} display="flex">
              <StyledLinkIcon />
            </Box>
            Amenti
          </StyledMenuItem>
        </StyledLink>
        <StyledLink href={`https://amenti.ch/${i18n.language}/terms-of-service`} underline="none" color="inherit" target="_blank">
          <StyledMenuItem>
            <Box mr={0.75} display="flex">
              <StyledLinkIcon />
            </Box>
            {t('pages:termsOfService')}
          </StyledMenuItem>
        </StyledLink>
        <StyledLink href={`https://amenti.ch/${i18n.language}/privacy-policy`} underline="none" color="inherit" target="_blank">
          <StyledMenuItem>
            <Box mr={0.75} display="flex">
              <StyledLinkIcon />
            </Box>
            {t('pages:privacyPolicy')}
          </StyledMenuItem>
        </StyledLink>
        <StyledLink href={`https://amenti.ch/${i18n.language}/cookie-policy`} underline="none" color="inherit" target="_blank">
          <StyledMenuItem>
            <Box mr={0.75} display="flex">
              <StyledLinkIcon />
            </Box>
            {t('pages:cookies')}
          </StyledMenuItem>
        </StyledLink>
      </StyledMenu>
    </Box>
  );
}

export default NavigationMenu
