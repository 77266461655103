import { StyledButton, BackIcon, StyledArrow, StyledClose } from './style'

const BackButton = ({ children, close, ...otherProps }) => (
  <StyledButton {...otherProps}>
    <BackIcon component="span">
      {close ? (
        <StyledClose />
      ) : (
        <StyledArrow />
      )}
    </BackIcon>
    {children}
  </StyledButton>
)

export default BackButton
