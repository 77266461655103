import { SvgIcon } from '@material-ui/core'

const Link = props => (
  <SvgIcon width='63' height='63' viewBox='0 0 63 63' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M57.1166 41.895V50.53C57.1086 57.324 51.5846 62.848 44.7906 62.856H13.0186C6.22464 62.848 0.700642 57.324 0.692642 50.53V18.758C0.700642 11.964 6.22464 6.44 13.0186 6.432H21.6536C22.8546 6.432 23.8286 7.406 23.8286 8.607C23.8286 9.808 22.8546 10.782 21.6536 10.782H13.0186C8.64664 10.79 5.05064 14.386 5.04264 18.758V50.53C5.05064 54.902 8.64664 58.498 13.0186 58.505H44.7906C49.1626 58.497 52.7586 54.901 52.7666 50.53V41.895C52.7666 40.694 53.7406 39.72 54.9416 39.72C56.1426 39.72 57.1166 40.694 57.1166 41.895ZM62.9166 2.807L62.2796 1.269L60.7416 0.631996H43.2496C42.0476 0.631996 41.0746 1.606 41.0746 2.807C41.0746 4.008 42.0486 4.982 43.2496 4.982H55.4896L33.9686 26.504C33.1196 27.353 33.1196 28.731 33.9686 29.58C34.8176 30.429 36.1956 30.429 37.0446 29.58L58.5656 8.059V20.3C58.5656 21.501 59.5396 22.475 60.7406 22.475C61.9416 22.475 62.9156 21.501 62.9156 20.3L62.9166 2.807Z' />
  </SvgIcon>
)

export default Link
