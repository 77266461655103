import React from 'react';
import { Helmet } from 'react-helmet';
import OgImg from '@/assets/images/og-image.jpg';
import { useTranslation } from 'react-i18next'

const SiteHelmet = (props) => {
  const { t } = useTranslation();
  const { pageTitle } = props;
  const siteTitle = t('siteTitle');
  const title = pageTitle ? `${pageTitle} - ${siteTitle}` : siteTitle;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta property="og:description" content={t('siteDescription')} />
      <meta property="og:image" content={OgImg} />
    </Helmet>
  );
};

export default SiteHelmet;
