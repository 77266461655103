import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import superagent from 'superagent'
import prefix from 'superagent-prefix'
import { changeVoucher } from '../redux/store'
import { useLocation } from 'react-router-dom'

const useQuery = () => new URLSearchParams(useLocation().search)

export const useInitialVoucherSetter = () => {
  const query = useQuery();
  const dispatch = useDispatch()
  const [initialVoucher, setInitialVoucher] = useState('');

  const getPrices = async (code) => {
    let url = '/api/data-app/TerrainExportZip/price';
    if (code) {
      url += `?voucher=${code}`;
    }
    const response = await superagent.get(url).use(prefix(process.env.REACT_APP_API_MAIN_URL));
    return response.body;
  };

  const setVoucher = async () => {
    const voucher = query.get('voucher')

    if (voucher) {
      const data = await getPrices(voucher);
      if (!data.discount?.error) {
        setInitialVoucher(voucher);
        dispatch(changeVoucher({ voucher: voucher }))
      }
    }
  }

  useEffect(() => {
    setVoucher()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return initialVoucher;
}
