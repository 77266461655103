import React, { useState } from 'react'
import { Box, Collapse } from '@material-ui/core'
import {
  AccordionArrow,
  AccordionHeadDescription,
  AccordionHeadSubtitle,
  AccordionHeadTitle,
  ArrowIcon,
  IconWrapper,
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionHead,
} from './style'

const AccordionContent = (props) => {
  const { children, small, noPadding } = props

  return (
    <StyledAccordionContent small={small} noPadding={noPadding}>
      <Box>{children}</Box>
    </StyledAccordionContent>
  )
}

const AccordionHead = (props) => {
  const {
    children,
    title,
    titleSecond,
    description,
    icon,
    small,
    noClose,
    animateIcon,
    expanded,
    setExpanded,
  } = props

  const [isAnimating, setIsAnimating] = useState(!!animateIcon)

  return (
    <StyledAccordionHead
      display='flex'
      flexDirection='column'
      justifyContent='center'
      onClick={() => {
        if (!noClose) {
          setExpanded(!expanded)
          setIsAnimating(false)
        }
      }}
      icon={icon}
      small={small}
    >
      {icon && (
        <IconWrapper isAnimating={isAnimating} small={small}>
          <Box>{icon}</Box>
        </IconWrapper>
      )}
      <Box>
        {title && (
          <AccordionHeadTitle
            fontWeight={400}
            color='textSecondary'
            component='span'
            small={small}
          >
            {title}
            {titleSecond && ': '}
          </AccordionHeadTitle>
        )}
        {titleSecond && (
          <AccordionHeadSubtitle
            fontWeight={700}
            component='span'
            small={small}
          >
            {titleSecond}
          </AccordionHeadSubtitle>
        )}
      </Box>
      <Box>
        <AccordionHeadDescription>{description}</AccordionHeadDescription>
      </Box>
      {!noClose && children && (
        <AccordionArrow expanded={expanded}>
          <ArrowIcon fontSize='medium' />
        </AccordionArrow>
      )}
    </StyledAccordionHead>
  )
}

const Accordion = (props) => {
  const { open, noClose, children, childAccordion } = props
  const [expanded, setExpanded] = useState(open || false)

  return (
    <StyledAccordion childAccordion={childAccordion}>
      <AccordionHead expanded={expanded} setExpanded={setExpanded} {...props} />
      <Collapse in={expanded || noClose}>
        {children && <AccordionContent {...props}>{children}</AccordionContent>}
      </Collapse>
    </StyledAccordion>
  )
}

const AccordionsWrapper = (props) => {
  const { children, className } = props
  return <div className={className}>{children}</div>
}

export { AccordionsWrapper }
export default Accordion
