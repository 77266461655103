import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import PageLayout from '../components/PageLayout';
import BackButton from '../components/BackButton';
import Typography from '../components/Typography';
import Button from '../components/Button';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const PaymentFailedRoute = () => {
  const [t] = useTranslation();
  const history = useHistory();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'payment_failed',
      },
    });
  }, []);

  const toPage = () => {
    history.replace('/payment');
  };

  return (
    <PageLayout>
      <Box mb={2}>
        <BackButton onClick={toPage}>{t('failedRoute:payment')}</BackButton>
      </Box>
      <Box mb={2}>
        <Box mb={1}>
          <Typography component='h1' variant='h2' fontWeight={500}>
            {t('failedRoute:problem')}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography color='textPrimary' variant='body1'>
            {t('failedRoute:error')}
          </Typography>
        </Box>
        <Box mt={4}>
          <Button onClick={toPage} size='large' color='primary' variant='contained'>
            {t('failedRoute:tryAgain')}
          </Button>
        </Box>
      </Box>
    </PageLayout>
  );
};

export default PaymentFailedRoute;
