import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import BackButton from '../components/BackButton'
import Typography from '../components/Typography'
import PageLayout from '../components/PageLayout'

const ImpressumRoute = () => {
  const [t] = useTranslation()
  const history = useHistory()

  const toPrevPage = () => {
    if (history.action !== 'POP') {
      history.goBack()
    }
  }

  const toMapPage = () => {
    history.push('/map/2d')
  }

  return (
    <PageLayout>
      <Box mb={2}>
        {history.action === 'POP' ? (
          <BackButton onClick={toMapPage}>{t('impressumRoute:explore')}</BackButton>
        ) : (
          <BackButton onClick={toPrevPage}>{t('impressumRoute:back')}</BackButton>
        )}
      </Box>
      <Box mb={4}>
        <Typography component='h1' variant='h3' fontWeight={500}>
          {t('impressumRoute:title')}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography color='textPrimary' fontWeight={500} variant='h3' component='h2'>
          Amenti AG
        </Typography>
        <Typography color='textSecondary' variant='caption'>
          CHE-384.083.884
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography color='textPrimary' fontWeight={500} variant='body1'>
          {t('impressumRoute:address')}
        </Typography>
        <Typography color='textPrimary' variant='body1'>
          Seehofstrasse 16, 8008 Zürich, Switzerland
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography color='textPrimary' fontWeight={500} variant='body1'>
          {t('impressumRoute:email')}
        </Typography>
        <Typography color='textPrimary' variant='body1'>
          info@amenti.ch
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography color='textPrimary' fontWeight={500} variant='body1'>
          {t('impressumRoute:contact')}
        </Typography>
        <Typography color='textPrimary' variant='body1'>
          Tõnu Mauring
        </Typography>
        <Typography color='textPrimary' variant='body1'>
          +41 (0) 79 890 18 13
        </Typography>
      </Box>
    </PageLayout>
  )
}

export default ImpressumRoute
