import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getMapDimensionTypeFromURL } from '@/helpers/routing'

export const useWhichMapType = () => {
  let location = useLocation()
  const [mapType, setMapType] = useState()

  useEffect(() => {
    const type = getMapDimensionTypeFromURL(location)
    setMapType(type)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return [mapType]
}
