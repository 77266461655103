import { Box } from '@material-ui/core'
import Typography from '../components/Typography'
import Button from '../components/Button'
import PageLayout from '../components/PageLayout'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const NotFoundRoute = () => {
  const [t] = useTranslation()
  const history = useHistory()

  const toPage = () => {
    history.replace('/map/2d')
  }

  return (
    <PageLayout>
      <Box mb={2} textAlign="center">
        <Box mb={1}>
          <Typography component='h1' variant='h1' fontWeight={500}>
            {t('notFoundRoute:title')}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography color='textPrimary' variant='body1'>
            {t('notFoundRoute:text')}
          </Typography>
        </Box>
        <Box mt={4}>
          <Button
            onClick={toPage}
            size='large'
            color='primary'
            variant='contained'
          >
            {t('notFoundRoute:back')}
          </Button>
        </Box>
      </Box>
    </PageLayout>
  )
}

export default NotFoundRoute
