import React, { useState, useEffect } from 'react';
import { LoadingProgress, LoadingView, Map } from './style';
import ThreeView from './3D';
import {
  callPostGisTerrain, fetchBuildingsByRadius,
} from './hooks';
import { useSelector } from 'react-redux';
import { useInitialLocationSetter } from '@/hooks/useInitialLocationSetter';
import { loadSatelliteImageAsTexture } from './utils';

const Component3D = ({ lng, lat }) => {
  const [loading, setLoading] = useState(true);
  const [terrain, setTerrain] = useState();
  const [elevation, setElevation] = useState(0);
  const [overlay, setOverlay] = useState();
  const [topoBuildings, setTopoBuildings] = useState([]);

  const loadData = async () => {
    const { elevation, midpointElevation } = await callPostGisTerrain(lng, lat);
    setTerrain(elevation);
    setElevation(midpointElevation);
    const mapOverlay = await loadSatelliteImageAsTexture({ lng, lat }, 200);
    setOverlay(mapOverlay);
    const buildings = await fetchBuildingsByRadius({ lng, lat });
    setTopoBuildings(buildings);
    setLoading(false);
  };

  useEffect(() => {
    if (lat && lng) loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng]);

  return loading ? (<LoadingView>
    <LoadingProgress width={20} height={20} />
  </LoadingView>) : (<ThreeView
    marker={{ lng, lat }}
    groundMatrix={terrain}
    midpointElevation={elevation}
    overlay={overlay}
    topoBuildings={topoBuildings}
  />);
};

const Map3D = ({ match }) => {
  const { lat, lng } = useSelector((state) => state.map.location);

  useInitialLocationSetter({ match });

  return (<Map>
    <Component3D lng={lng} lat={lat} />
  </Map>);
};

export default Map3D;
