import * as yup from 'yup'

const getSchema = (fieldType) => {
  let fieldSchema

  switch (fieldType) {
    case 'number':
      fieldSchema = yup.object().shape({
        value: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .when('$required', (required, schema) =>
            required ? schema.required('Is required!') : schema.nullable(true),
          )
          .when('$min', (min, schema) =>
            min !== null ? schema.min(min, `Minimum of ${min}`) : schema,
          )
          .when('$max', (max, schema) =>
            max !== null ? schema.max(max, `Maximum of ${max}`) : schema,
          ),
      })
      break
    case 'text':
      fieldSchema = yup.object().shape({
        value: yup
          .string()
          .when('$required', (required, schema) =>
            required ? schema.required('Required') : schema,
          )
          .when('$min', (min, schema) =>
            min ? schema.min(min, `Minimum length of ${min}`) : schema,
          )
          .when('$max', (max, schema) =>
            max ? schema.max(max, `Maximum length of ${max}`) : schema,
          ),
      })
      break
    default:
      fieldSchema = yup.object().shape({
        value: yup.string(),
      })
      break
  }

  return fieldSchema
}

export default getSchema
