import StaticMap from '@rkaravia/static-map';
import proj4 from 'proj4';
import * as THREE from 'three';

// Source: https://api3.geo.admin.ch/services/sdiservices.html#wmts
const RESOLUTIONS = [
  4000, 3750, 3500, 3250, 3000, 2750, 2500, 2250, 2000, 1750, 1500, 1250, 1000, 750, 650, 500, 250, 100, 50, 20, 10, 5,
  2.5, 2, 1.5, 1, 0.5, 0.25, 0.1,
];
const textureZoom = 27;
const textureTileSize = 256;
const tileWidth = 64;
const pixelsPerMeter = textureTileSize / tileWidth;
const sourceProj = 'EPSG:4326';
const destinationProj = 'EPSG:21781';
proj4.defs(
  destinationProj,
  '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.4,15.1,405.3,0,0,0,0 +units=m +no_defs',
);

function lonLatToPixelWmts(props) {
  const { lon, lat, zoom } = props;

  const [xSwiss, ySwiss] = proj4(sourceProj, destinationProj, [lon, lat]);

  // Calculate the tile coordinates (x, y) based on the Swiss coordinates, zoom level, and tile size
  const resolution = RESOLUTIONS[zoom];
  const X_OFFSET = 420000;
  const Y_OFFSET = 350000;

  const x = Math.floor((xSwiss - X_OFFSET) / resolution);
  const y = Math.floor((Y_OFFSET - ySwiss) / resolution);

  return [x, y];
}

const loadToCanvas = async (props)=> {
  const { center, zoom, tileSize, size, url } = props;
  const staticMap = new StaticMap(url, { size: tileSize, lonLatToPixel: lonLatToPixelWmts });
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;

  return new Promise((resolve) => {
    staticMap.getMap(canvas, center.lng, center.lat, zoom, () => {
      resolve(canvas);
    });
  });
};

const loadImageAsTexture = async (url, center, width) => {
  const canvas = await loadToCanvas({
    center,
    zoom: textureZoom,
    tileSize: textureTileSize,
    size: pixelsPerMeter * width,
    url,
  });
  const texture = new THREE.CanvasTexture(canvas);

  texture.minFilter = THREE.NearestFilter;
  texture.magFilter = THREE.NearestFilter;
  texture.generateMipmaps = false;

  return texture;
};

export const loadSatelliteImageAsTexture = async (center, width) => {
  const textureUrl = 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/2056/{z}/{x}/{y}.jpeg';

  return await loadImageAsTexture(textureUrl, center, width);
};
