import React, { useEffect, useState } from 'react'
import { bearing, point } from '@turf/turf'
import superagent from 'superagent'
import skylineImage from '@/assets/images/skyline.svg'
import { Wrapper, StyledImg } from './style'

function getBearing(start, end) {
  return bearing(point([start.lng, start.lat]), point([end.lng, end.lat]))
}

const getHasGoogleStreetViewImage = async (url, setStreetViewCenter) => {
  const res = await superagent.get(url)
  const streetViewCenter = res?.body?.location
  if (res.body.status === 'OK' && streetViewCenter)
    setStreetViewCenter(streetViewCenter)
}

const GoogleStreetView = props => {
  const {
    center,
    width,
    height,
    onStreetViewLoaded = () => {},
  } = props
  const [streetViewCenter, setStreetViewCenter] = useState(null)
  const [heading, setHeading] = useState(null)
  const location = `location=${center?.lat},${center?.lng}`
  const size = `size=${width}x${height}`
  const apiKey = `key=${'AIzaSyCTPkYizUbmJYJ7MU86VhpsqsyGAzQyVxI'}`
  const [loaded, setLoaded] = useState(true)

  React.useEffect(() => {
    if (!streetViewCenter) return
    const heading = getBearing(streetViewCenter, center)
    setHeading(heading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streetViewCenter])

  const pitch = `pitch=${15}`
  const source = `source=outdoor`
  const metadataUrl = `https://maps.googleapis.com/maps/api/streetview/metadata?${size}&${location}&${source}&${apiKey}&preference=best`

  useEffect(() => {
    getHasGoogleStreetViewImage(metadataUrl, setStreetViewCenter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center])

  return (
    <Wrapper>
      {center &&
      <>
        <StyledImg
          src={`https://maps.googleapis.com/maps/api/streetview?${size}&${location}&heading=${heading}&${pitch}&${source}&${apiKey}&fov=99&preference=best`}
          alt='type'
          height={height}
          width={width}
          onLoad={() => setLoaded(true)}
          shown={loaded && streetViewCenter && heading}
        />
        <StyledImg
          src={skylineImage}
          onLoad={onStreetViewLoaded}
          width={width}
          alt='Street view is not available'
          shown={!loaded || !streetViewCenter || !heading}
          style={{
            pointerEvents: 'none'
          }}
        />
      </>
      }
    </Wrapper>
  )
}

export default GoogleStreetView
