import React from 'react'
import tzlookup from 'tz-lookup'
import moment from 'moment-timezone'
import SunCalc from 'suncalc'

const getPositionAndVisibility = (center, date) => {
  const { lng, lat } = center || {
    lat: 47.362,
    lng: 8.532,
  }
  const timezone = tzlookup(lat, lng)
  moment.tz.setDefault(timezone)
  const sceneDate = moment(date).toDate()
  const sunInfo = SunCalc.getPosition(sceneDate, lat, lng)
  let x = 0,
    y = 0
  const z = Math.tan(sunInfo['altitude'])
  if (sunInfo['azimuth'] > -Math.PI / 2 && sunInfo['azimuth'] < Math.PI / 2) {
    x = -Math.tan(sunInfo['azimuth'])
    y = -1
  } else {
    x = Math.tan(sunInfo['azimuth'])
    y = 1
  }
  return {
    position: [x, y, z],
    visible: !(z < 0),
  }
}

const SunLight = ({ center, date }) => {
  const { position, visible } = getPositionAndVisibility(center, date)
  return (
    <directionalLight
      castShadow
      position={position}
      visible={visible}
      color='#FFFFFF'
      intensity={1}
      shadow-mapSize-height={1024}
      shadow-mapSize-width={1024}
      shadow-bias={-0.01}
      shadow-camera-left={-200}
      shadow-camera-right={200}
      shadow-camera-top={200}
      shadow-camera-bottom={-200}
      shadow-camera-near={-200}
      shadow-camera-far={200}
    />
  )
}

export default SunLight