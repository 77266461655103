import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import ArrowDown from '../Icons/ArrowDown'
import Fab from '../Fab'
import Close from '../Icons/Close'

const StyledButton = styled(Button)`
  padding: 0;
  color: ${({theme}) => theme.palette.grey[600]};
  transition: opacity 150ms ease-out;
  
  &:hover {
    opacity: 0.75;
    background-color: transparent;
  }
`

const BackIcon = styled(Fab)`
  width: 30px;
  height: 30px;
  min-height: 0;
  border: 1px solid ${({theme}) => theme.palette.grey[400]};
  background-color: transparent;
  margin-right: ${({theme}) => theme.spacing(1)}px;
  
  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
`

const StyledArrow = styled(ArrowDown)`
  transform: rotate(90deg) translateY(1px);
  color: ${({theme}) => theme.palette.grey[600]};
`

const StyledClose = styled(Close)`
  font-size: 14px;
  color: ${({theme}) => theme.palette.grey[600]};
`

export { StyledButton, BackIcon, StyledArrow, StyledClose }
