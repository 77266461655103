import styled, { css } from 'styled-components'
import Button from '../Button'
import { Menu, MenuItem, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import LinkIcon from '@/components/Icons/Link'
import { shadows } from '../../theme/theme'

const MenuToggle = styled.div``
const MenuSpan = styled.span``

const MenuButton = styled(Button)`
  cursor: pointer;
  width: 50px;
  height: 40px;
  padding: 10px;
  transform: translateX(10px);
  min-width: 0;
  overflow: hidden;

  ${MenuToggle} {
    position: relative;
    width: 25px;
    height: 20px;
    transition: 0.5s ease-in-out;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 25px;
      border-radius: 4px;
      background: #FFF;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 8px
      }

      &:nth-child(4) {
        top: 16px
      }
    }
  }

  ${({ open }) => open && css`
    ${MenuSpan} {
      background: #FFF;

      &:nth-child(1) {
        width: 0;
        right: 50%;
        opacity: 0;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        width: 0;
        left: 100%;
        opacity: 0;
      }
    }
  `}
`

const StyledMenu = styled(Menu)`
  z-index: 10000 !important;
  
  .MuiPaper-root {
    margin-top: -${({ theme }) => theme.spacing(0.5)}px;
    border: 1px solid ${({ theme }) => theme.palette.grey[300]};
    box-shadow: ${shadows.large};
  }
  
  .MuiList-root {
    padding: 0;
  }
`

const StyledMenuItem = styled(MenuItem)`
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 500;
  min-width: 125px;
  min-height: 0;
  display: ${({ activeLanguage }) => activeLanguage ? 'none' : 'flex'};
`

const StyledRouterLink = styled(RouterLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.grey[900]};
`

const StyledLinkIcon = styled(LinkIcon)`
  font-size: 12px;
`

const StyledLink = styled(Link)``

export { MenuButton, MenuToggle, MenuSpan, StyledMenu, StyledMenuItem, StyledRouterLink, StyledLink, StyledLinkIcon }
