const metersDeglon = (x) => {
  const degrees = (x * Math.PI) / 180
  const b = 6356752.3142
  const a = 6378137.0
  const eccSquared = (a * a - b * b) / (a * a)
  const eSinSquared = eccSquared * Math.pow(Math.sin(degrees), 2)

  return (
    (((Math.PI * a) / 180) * Math.cos(degrees)) / Math.sqrt(1 - eSinSquared)
  )
}

const metersDeglat = (x) => {
  const degrees = (x * Math.PI) / 180
  const b = 6356752.3142
  const a = 6378137.0
  const eccSquared = (a * a - b * b) / (a * a)
  const eSinSquared = eccSquared * Math.pow(Math.sin(degrees), 2)

  return (
    (((Math.PI * a) / 180) * (1 - eccSquared)) /
    Math.pow(1 - eSinSquared, 3.0 / 2.0)
  )
}

const wgsToLocal = (coordinates, ref) => {
  let localPoints = []
  for (let i in coordinates) {
    const coord = coordinates[i]
    const x = (coord['lng'] - ref.lng) * metersDeglon(ref.lat)
    const y = (coord['lat'] - ref.lat) * metersDeglat(ref.lat)
    localPoints.push([x, y])
  }
  return localPoints
}

const localToWgs = (points, ref) => {
  let coordinates = []
  for (let point of points) {
    const coord = [
      point[0] / metersDeglon(ref.lat) + ref.lng,
      point[1] / metersDeglat(ref.lat) + ref.lat,
    ]
    coordinates.push(coord)
  }
  return coordinates
}

export { wgsToLocal, localToWgs }