import { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom'
import { Provider } from 'react-redux'
import store from '@/redux/store'

import {
  MapRoute,
  PlotRoute,
  PaymentSuccessRoute,
  PaymentFailedRoute,
  PaymentRoute,
  NotFoundRoute,
  FaqRoute,
} from '@/routes'
import AppBar from '@/components/AppBar'
import { Map2D } from '@/components/Map'
import Map3D from '@/components/Map/Map3D'
import { AnimatePresence, motion } from 'framer-motion'
import NavigationMenu from '@/components/NavigationMenu'
import SiteHelmet from '@/components/SiteHelmet'
import { useTranslation } from 'react-i18next'
import { useLanguageQueryParam } from './hooks/useQuery'
import ImpressumRoute from './routes/ImpressumRoute'
import TagManager from 'react-gtm-module'

const AnimationWrapper = ({ children }) => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: 60,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: -60,
    },
  }

  const pageTransitions = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5,
  }

  return (
    <motion.div
      initial='initial'
      animate='in'
      exit='out'
      variants={pageVariants}
      transition={pageTransitions}
    >
      {children}
    </motion.div>
  )
}

const MapAnimationWrapper = ({ children }) => {
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  }

  const pageTransitions = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4,
  }

  return (
    <motion.div
      initial='initial'
      animate='in'
      exit='out'
      variants={pageVariants}
      transition={pageTransitions}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      {children}
    </motion.div>
  )
}

const AppContent = () => {
  const location = useLocation()
  const { t } = useTranslation()
  useLanguageQueryParam()

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight()
    TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_ANALYTICS })
  }, [])

  return (
    <>
      <AppBar right={<NavigationMenu />} />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.key}>
          <Route exact path='/'>
            <Redirect to='/map/2d' />
          </Route>
          <Route
            path='/map'
            render={({ match: { url } }) => (
              <>
                <SiteHelmet />
                <MapRoute>
                  <Switch location={location} key={location.key}>
                    <Route
                      path={`${url}/2d`}
                      render={({ match: { url } }) => (
                        <MapAnimationWrapper>
                          <Switch location={location} key={location.key}>
                            <Route
                              path={`${url}`}
                              component={Map2D}
                            />
                            <Route
                              path={`${url}/:lat,:lng,:zoom`}
                              component={Map2D}
                            />
                            <Route
                              path={`${url}/:lat,:lng`}
                              component={Map2D}
                            />
                            <Route path={`${url}`} component={Map2D} />
                          </Switch>
                        </MapAnimationWrapper>
                      )}
                    />
                    <Route path={`${url}/3d/:lat,:lng`} component={Map3D} />
                  </Switch>
                </MapRoute>
              </>
            )}
          />
          <Route
            path='/site'
            children={
              <>
                <SiteHelmet pageTitle={t('pages:site')} />
                <AnimationWrapper>
                  <PlotRoute />
                </AnimationWrapper>
              </>
            }
          />
          <Route
            path='/faq'
            children={
              <>
                <SiteHelmet pageTitle={t('pages:faq')} />
                <AnimationWrapper>
                  <FaqRoute />
                </AnimationWrapper>
              </>
            }
          />
          <Route
            path='/impressum'
            children={
              <>
                <SiteHelmet pageTitle={t('pages:impressum')} />
                <AnimationWrapper>
                  <ImpressumRoute />
                </AnimationWrapper>
              </>
            }
          />
          <Route
            path='/payment'
            render={({ match: { url } }) => (
              <Switch>
                <Route
                  path={`${url}/`}
                  children={
                    <>
                      <SiteHelmet pageTitle={t('pages:payment')} />
                      <AnimationWrapper>
                        <PaymentRoute />
                      </AnimationWrapper>
                    </>
                  }
                  exact
                />
                <Route
                  path={`${url}/success`}
                  children={
                    <AnimationWrapper>
                      <PaymentSuccessRoute />
                    </AnimationWrapper>
                  }
                />
                <Route
                  path={`${url}/failed`}
                  children={
                    <>
                      <SiteHelmet pageTitle={t('pages:paymentFailed')} />
                      <AnimationWrapper>
                        <PaymentFailedRoute />
                      </AnimationWrapper>
                    </>
                  }
                />
              </Switch>
            )}
          />
          <Route
            children={
              <>
                <SiteHelmet pageTitle={t('pages:notFound')} />
                <AnimationWrapper>
                  <NotFoundRoute />
                </AnimationWrapper>
              </>
            }
          />
        </Switch>
      </AnimatePresence>
    </>
  )
}

function App() {
  return (
    <Router>
      <Provider store={store}>
        <AppContent />
      </Provider>
    </Router>
  )
}

export default App
