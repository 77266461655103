import styled, { css } from 'styled-components'
import {
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
} from '@material-ui/core'

const StyledFormControl = styled(FormControl)`
  ${({ labelInside }) =>
  labelInside &&
  css`
      width: 100%;
      max-width: none !important;
      height: 100%;

      .MuiInputAdornment-root {
        height: 100%;
        box-sizing: border-box;
        padding: 25px 8px 6px;
      }

      .MuiInputBase-input {
        height: 100%;
        box-sizing: border-box;
        padding: 25px 0 6px 8px !important;
      }

      .MuiFormLabel-root {
        position: absolute;
        z-index: 1;
        font-size: 12px;
        transform: translate(0, 8px);
        padding: 0 8px;
        color: ${({ theme }) => theme.palette.grey[700]};
      }

      .MuiInputAdornment-root {
        .MuiTypography-root {
          font-size: 11px;
          color: ${({ theme }) => theme.palette.grey[100]};
        }
      }

      .MuiInputBase-input {
        &:hover {
          -moz-appearance: auto;
        }

        &::-webkit-outer-spin-button {
          -webkit-appearance: auto;
          margin: unset;
        }

        &::-webkit-inner-spin-button {
          -webkit-appearance: auto;
          margin: unset;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${({ theme }) => theme.palette.grey[300]};
        transition: border 200ms ease-out;
      }

      .MuiInputBase-root {
        height: 100%;
        padding: 0;
        margin-top: 0;
        font-size: 16px;
        color: ${({ theme }) => theme.palette.grey[900]} !important;
        font-weight: 400;
        transition: color 200ms ease-out;

        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid ${({ theme }) => theme.palette.grey[400]};
          }

          &.Mui-error {
            .MuiOutlinedInput-notchedOutline {
              border: 1px solid ${({ theme }) => theme.palette.error.main};
            }
          }
        }

        &.Mui-error {
          color: ${({ theme }) => theme.palette.error.main};

          .MuiOutlinedInput-notchedOutline {
            border: 1px solid ${({ theme }) => theme.palette.error.main};
          }

          .MuiInputAdornment-root {
            .MuiTypography-root {
              color: ${({ theme }) => theme.palette.error.main};
            }
          }
        }

        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid ${({ theme }) => theme.palette.grey[500]};
          }
        }
      }

      .MuiFormHelperText-root {
        line-height: 1.2;
        margin: 3px 0 0;
        font-size: 9px;
        color: ${({ theme }) => theme.palette.error.main};
      }
    `}
  
  ${({ width }) => width && css`
    width: 100%;
  `}
`

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.grey[200]};
    transition: ${({ theme }) =>
            `border-color 200ms ${theme.transitions.easing.easeInOut}`};
  }

  .MuiOutlinedInput-adornedEnd {
    ${({ size, labelInside, theme }) => (size === 'medium' && !labelInside) && css`
      padding-right: ${theme.spacing(0.5)}px;
    `};
  }

  .MuiInputBase-adornedStart {
    ${({ size, theme }) =>
            size === 'small' &&
            css`
              padding-left: ${theme.spacing(1)}px;
            `};
  }

  .MuiInputBase-adornedEnd {
    ${({ size, theme }) =>
            size === 'small' &&
            css`
              padding-right: ${theme.spacing(1)}px;
            `};
  }

  .MuiInputBase-input {
    padding: ${({ theme }) => `${theme.spacing(1)}px`};
    ${({ size, theme }) =>
            size === 'small' &&
            css`
              font-size: 12px;
            `};
  }

  .MuiFormHelperText-root {
    margin: ${({ theme }) => `${theme.spacing(0.5)}px 0 0`};
  }

  .MuiInputAdornment-root {
    .MuiTypography-root {
      font-size: 14px;
      color: ${({ theme }) => theme.palette.grey[600]};
      transition: ${({ theme }) =>
              `color 200ms ${theme.transitions.easing.easeInOut}`};

      ${({ size }) =>
              size === 'small' &&
              css`
                font-size: 11px;
              `};
    }
  }

  .MuiInputBase-root {
    background-color: ${({ theme }) => theme.palette.grey[50]};

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.grey[400]};
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-color: ${({ theme }) => theme.palette.grey[800]};
      }

      .MuiInputAdornment-root {
        .MuiTypography-root {
          color: ${({ theme }) => theme.palette.grey[800]};
        }
      }
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.error.main};
      }
    }
  }
`

const StyledInputAdornment = styled(InputAdornment)``

const StyledInputLabel = styled(InputLabel)`
  position: static;
  transform: none;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`

export {
  StyledFormControl,
  StyledTextField,
  StyledInputAdornment,
  StyledInputLabel,
}
