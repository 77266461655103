import { Wrapper, StyledError, StyledField, StyledLabel } from './style'

const FormikField = props => {
  const { fieldName, type, errors, touched, t, required } = props;

  return (
    <>
      <Wrapper error={(errors[fieldName] && touched[fieldName]) && 'input-error'}>
        <StyledLabel for={fieldName}>
          {t(`form:${fieldName}`)}{required && '*'}
          <StyledError name={fieldName} component="div" />
        </StyledLabel>
        <StyledField
          type={type}
          name={fieldName}
          placeholder="‒"
          id={fieldName}
        />
      </Wrapper>
    </>
  )
}

export default FormikField
