import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Tooltip } from '@material-ui/core';
import * as Yup from 'yup';
import proj4 from 'proj4';
import PageLayout from '../components/PageLayout';
import Typography from '../components/Typography';
import GoogleStreetView from '../components/GoogleStreetView';
import Separator from '../components/Separator';
import Button from '../components/Button';
import BackButton from '../components/BackButton';
import { useHistory } from 'react-router-dom';
import { updateUser } from '../redux/store';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import styled from 'styled-components';
import FormikField from '../components/FormikField';
import TagManager from 'react-gtm-module';

const wgsProj = '+proj=longlat +datum=WGS84 +no_defs';
const lv =
  '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs';

const StyledField = styled(Field)`
  width: 100%;
  padding: 10px 12px;
  background-color: transparent;
  box-shadow: none;
  color: ${({ theme }) => theme.palette.grey[900]};
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  transition: border 150ms ease-out;

  &::placeholder {
    color: ${({ theme }) => theme.palette.grey[300]};
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  }

  &.input-error {
    color: ${({ theme }) => theme.palette.error.main};
    border: 1px solid ${({ theme }) => theme.palette.error.main};
    outline: 0;
    box-shadow: none;

    &::placeholder {
      color: ${({ theme }) => theme.palette.error.main};
    }
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
`;

const FieldLabel = styled.label`
  display: block;
  width: 100%;
  text-align: left;
  font-size: 12px;
`;

const StyledError = styled(ErrorMessage)`
  text-align: left;
  padding: 6px 0 0;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.error.main};
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.palette.common.black};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const StyledCheckbox = styled.div`
  position: relative;

  label {
    position: relative;
    padding: 0 0 0 25px;
    font-size: 14px;

    &:before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      width: 16px;
      height: 16px;
      border-radius: 3px;
      border: 1px solid ${({ theme }) => theme.palette.grey[400]};
      transition: all 0.25s ease-out;
    }

    &:after {
      content: '';
      position: absolute;
      top: 8px;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.palette.grey[800]};
      transform: scale(0);
      opacity: 0;
      transition: all 0.25s ease-out;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked + label {
      &:before {
        border: 1px solid ${({ theme }) => theme.palette.grey[800]};
      }

      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
`;

const PlotRoute = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { lat, lng, municipality, canton, address } = useSelector((state) => state.map.location);
  const [x, y] = proj4(wgsProj, lv, [Number(lng), Number(lat)]);

  const { fname, lname, email, address: userAddress, postal, city, agree, company, agreeNewsletter } = useSelector((state) => state.user);

  const handleSubmit = async (values) => {
    await dispatch(updateUser(values));
    toNextPage();
  };

  const toPrevPage = () => {
    history.push('/map/2d');
  };

  const toNextPage = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'details_confirmed',
      },
    });
    history.push('/payment');
  };

  useEffect(() => {
    if (!lat && !lng) {
      history.replace('/map/2d');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout>
      <Box mb={2}>
        <BackButton onClick={toPrevPage}>{t('plotRoute:plotSearch')}</BackButton>
      </Box>
      <Box mb={2}>
        <Box mb={1}>
          <Typography component='h1' variant='h3' fontWeight={500}>
            {address || t('plotRoute:selectedTerrain')}
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={4}>
            <Box>
              <Typography color='textSecondary' variant='body2'>
                {t('plotRoute:municipality')}:
              </Typography>
              <Typography color='textSecondary' variant='body2'>
                {municipality}
              </Typography>
            </Box>
            <Box>
              <Typography color='textSecondary' variant='body2'>
                {t('plotRoute:canton')}: {canton}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Typography color='textSecondary' variant='body2'>
                LV95
              </Typography>
            </Box>
            <Box>
              <Typography color='textSecondary' variant='body2'>
                x - {x.toFixed(2)}
              </Typography>
            </Box>
            <Box>
              <Typography color='textSecondary' variant='body2'>
                y - {y.toFixed(2)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Typography color='textSecondary' variant='body2'>
                WGS84
              </Typography>
            </Box>
            <Box>
              <Typography color='textSecondary' variant='body2'>
                Lng - {Number(lng).toFixed(5)}
              </Typography>
            </Box>
            <Box>
              <Typography color='textSecondary' variant='body2'>
                Lat - {Number(lat).toFixed(5)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box my={2}>
          <GoogleStreetView center={{ lat, lng }} width={600} height={200} />
        </Box>
        <Box my={4}>
          <Separator />
        </Box>
        <Box mb={1}>
          <Typography component='h2' variant='h3' fontWeight={500}>
            {t('plotRoute:downloadZip')}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography color='textSecondary' variant='body2'>
            {t('plotRoute:enterEmail')}
          </Typography>
          <Typography color='textSecondary' variant='body2'>
            {t('plotRoute:zipContains')}
          </Typography>
        </Box>
        <Box mb={2}>
          <Formik
            validateOnMount
            enableReinitialize
            initialValues={{ fname, lname, email, address: userAddress, postal, city, agree, company, agreeNewsletter }}
            validationSchema={Yup.object().shape({
              fname: Yup.string().min(1, t('form:tooShort')).max(100, t('form:maxLength')).required(t('form:required')),
              lname: Yup.string().min(1, t('form:tooShort')).max(100, t('form:maxLength')).required(t('form:required')),
              email: Yup.string().email(t('form:emailError')).max(100, t('form:maxLength')).required(t('form:required')),
              address: Yup.string().min(1, t('form:tooShort')).max(100, t('form:maxLength')).required(t('form:required')),
              postal: Yup.string().min(1, t('form:tooShort')).max(100, t('form:maxLength')).required(t('form:required')),
              company: Yup.string().max(100, t('form:maxLength')),
              city: Yup.string().min(1, t('form:tooShort')).max(100, t('form:maxLength')).required(t('form:required')),
              agree: Yup.boolean().oneOf([true], t('form:agreeRequired')),
              agreeNewsletter: Yup.boolean().oneOf([true, false], t('form:newsletterAgreeRequired')),
            })}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid } = formik;

              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormikField fieldName='email' type='email' t={t} errors={errors} touched={touched} required />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikField fieldName='fname' type='text' t={t} errors={errors} touched={touched} required />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikField fieldName='lname' type='text' t={t} errors={errors} touched={touched} required />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikField fieldName='company' type='text' t={t} errors={errors} touched={touched} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikField fieldName='address' type='text' t={t} errors={errors} touched={touched} required />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <FormikField fieldName='postal' type='text' t={t} errors={errors} touched={touched} required />
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <FormikField fieldName='city' type='text' t={t} errors={errors} touched={touched} required />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledCheckbox>
                        <StyledField type='checkbox' name='agree' id='agree' className={errors.agree && touched.agree ? 'input-error' : null} />
                        <FieldLabel htmlFor='agree' className='mb-1'>
                          {t('form:agreement:one')}
                          <StyledLink href='https://amenti.ch/terms-of-service' target='_blank'>
                            {t('form:agreement:two')}
                          </StyledLink>
                          {t('form:agreement:three')} *
                        </FieldLabel>
                      </StyledCheckbox>
                      <StyledError noPadding name='agree' component='div' />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledCheckbox>
                        <StyledField
                          type='checkbox'
                          name='agreeNewsletter'
                          id='agreeNewsletter'
                          className={errors.agreeNewsletter && touched.agreeNewsletter ? 'input-error' : null}
                        />
                        <FieldLabel htmlFor='agreeNewsletter' className='mb-1'>
                          {t('form:agreeNewsletter')}
                        </FieldLabel>
                      </StyledCheckbox>
                      <StyledError noPadding name='agreeNewsletter' component='div' />
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    <Typography color='textSecondary' variant='body2'>
                      {t('form:asteriskRequired')}
                    </Typography>
                  </Box>
                  <Box mt={4} textAlign='center'>
                    {isValid ? (
                      <Button type='submit' size='large' color='primary' variant='contained' style={{ width: '100%' }}>
                        {t('plotRoute:continue')}
                      </Button>
                    ) : (
                      <Tooltip title={t('form:enterValidDetails')} arrow placement='top'>
                        <Box display='block' width='100%'>
                          <Button size='large' color='primary' variant='contained' disabled={true} style={{ width: '100%' }}>
                            {t('plotRoute:continue')}
                          </Button>
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </PageLayout>
  );
};
export default PlotRoute;
