import * as THREE from 'three'

class GroundHelper {
  constructor(groundMatrix) {
    this.groundMatrix = groundMatrix
    this.ground = null
    this.topGeometry = null
    this.bottomGeometry = null
    this.gridGeometry = null
    this.overlayGeometry = null
    this.minimalPoint = null
    this.maximalPoint = null
  }

  getGeometry() {
    this.topGeometry = new THREE.Geometry()
    this.bottomGeometry = new THREE.Geometry()

    this._getMinimalAndMaximalPoints()
    this._addVertices()
    this._addFaces()
    this._addBottomVertices()
    this._addBottomFaces()

    return {
      topGeometry: this.topGeometry,
      bottomGeometry: this.bottomGeometry,
    }
  }

  getGridGeometry() {
    this.gridGeometry = new THREE.Geometry()
    for (let i = 0, count = this.groundMatrix.length - 1; i < count; i++) {
      for (let j = 0, count2 = this.groundMatrix.length - 1; j < count2; j++) {
        const fv = this.groundMatrix[i][j]
        const sv = this.groundMatrix[i][j + 1]
        const tv = this.groundMatrix[i + 1][j]
        this.gridGeometry.vertices.push(
          new THREE.Vector3(fv[0], fv[1], fv[2]),
          new THREE.Vector3(sv[0], sv[1], sv[2]),
          new THREE.Vector3(fv[0], fv[1], fv[2]),
          new THREE.Vector3(tv[0], tv[1], tv[2]),
        )
      }
    }
    for (let i = 0, count = this.groundMatrix.length - 1; i < count; i++) {
      const v1 = this.groundMatrix[count][i]
      const v2 = this.groundMatrix[count][i + 1]
      const h1 = this.groundMatrix[i][count]
      const h2 = this.groundMatrix[i + 1][count]
      this.gridGeometry.vertices.push(
        new THREE.Vector3(v1[0], v1[1], v1[2]),
        new THREE.Vector3(v2[0], v2[1], v2[2]),
        new THREE.Vector3(h1[0], h1[1], h1[2]),
        new THREE.Vector3(h2[0], h2[1], h2[2]),
      )
    }
    return this.gridGeometry
  }

  getOverlayGeometry() {
    this.overlayGeometry = new THREE.Geometry()
    for (let i = 0, count = this.groundMatrix.length; i < count; i++) {
      for (let j = 0, count2 = this.groundMatrix[i].length; j < count2; j++) {
        const vertex = this.groundMatrix[i][j]
        this.overlayGeometry.vertices.push(
          new THREE.Vector3(vertex[0], vertex[1], vertex[2]),
        )
      }
    }
    const faceCount = this.groundMatrix.length - 1
    const delta = faceCount > 0 ? 1 / faceCount : 0
    let imgX = 0
    let imgY = 0
    for (let i = 0, count = this.groundMatrix.length - 1; i < count; i++) {
      for (let j = 0, count2 = this.groundMatrix.length - 1; j < count2; j++) {
        let vertex1Index = i * this.groundMatrix.length + j
        let vertex2Index = i * this.groundMatrix.length + j + 1
        let vertex3Index = (i + 1) * this.groundMatrix.length + j
        let vertex4Index = (i + 1) * this.groundMatrix.length + j + 1
        this.overlayGeometry.faces.push(
          new THREE.Face3(vertex1Index, vertex3Index, vertex2Index),
          new THREE.Face3(vertex3Index, vertex4Index, vertex2Index),
        )
        this.overlayGeometry.faceVertexUvs[0].push([
          new THREE.Vector2(imgX, imgY),
          new THREE.Vector2(imgX, imgY + delta),
          new THREE.Vector2(imgX + delta, imgY),
        ])
        this.overlayGeometry.faceVertexUvs[0].push([
          new THREE.Vector2(imgX, imgY + delta),
          new THREE.Vector2(imgX + delta, imgY + delta),
          new THREE.Vector2(imgX + delta, imgY),
        ])
        imgX += delta
      }
      imgX = 0
      imgY += delta
    }
    return this.overlayGeometry
  }

  _addVertices() {
    for (let i = 0, count = this.groundMatrix.length; i < count; i++) {
      for (let j = 0, count2 = this.groundMatrix[i].length; j < count2; j++) {
        const vertex = this.groundMatrix[i][j]
        this.topGeometry.vertices.push(
          new THREE.Vector3(vertex[0], vertex[1], vertex[2]),
        )
        this.bottomGeometry.vertices.push(
          new THREE.Vector3(vertex[0], vertex[1], vertex[2]),
        )
      }
    }
  }

  _addFaces() {
    for (let i = 0, count = this.groundMatrix.length - 1; i < count; i++) {
      for (let j = 0, count2 = this.groundMatrix.length - 1; j < count2; j++) {
        const vertex1Index = i * this.groundMatrix.length + j + 1
        const vertex2Index = i * this.groundMatrix.length + j
        const vertex3Index = (i + 1) * this.groundMatrix.length + j
        const vertex4Index = (i + 1) * this.groundMatrix.length + j + 1
        this.topGeometry.faces.push(
          new THREE.Face3(vertex1Index, vertex2Index, vertex3Index),
          new THREE.Face3(vertex1Index, vertex3Index, vertex4Index),
        )
      }
    }
  }

  _addBottomVertices() {
    for (let i = 0, count = this.groundMatrix.length; i < count; i++) {
      if (i === 0 || i === this.groundMatrix.length - 1) {
        for (let j = 0, count2 = this.groundMatrix.length; j < count2; j++) {
          const vertex = this.groundMatrix[i][j]
          this.bottomGeometry.vertices.push(
            new THREE.Vector3(vertex[0], vertex[1], this.minimalPoint[2] - 10),
          )
        }
      } else {
        const firstColumnVertex = this.groundMatrix[i][0]
        const lastColumnVertex =
          this.groundMatrix[i][this.groundMatrix.length - 1]
        this.bottomGeometry.vertices.push(
          new THREE.Vector3(
            firstColumnVertex[0],
            firstColumnVertex[1],
            this.minimalPoint[2] - 10,
          ),
          new THREE.Vector3(
            lastColumnVertex[0],
            lastColumnVertex[1],
            this.minimalPoint[2] - 10,
          ),
        )
      }
    }
  }

  _addBottomFaces() {
    const countTop = this.groundMatrix.length ** 2
    // const countBottom = 2 * this.groundMatrix.length + 2 * (this.groundMatrix.length - 2);

    for (let i = 0, count = this.groundMatrix.length - 1; i < count; i++) {
      this.bottomGeometry.faces.push(
        new THREE.Face3(i, i + 1, i + countTop),
        new THREE.Face3(i + 1, i + countTop, i + countTop + 1),
      )
    }

    for (let i = 0, count = this.groundMatrix.length - 1; i < count; i++) {
      this.bottomGeometry.faces.push(
        new THREE.Face3(
          countTop - this.groundMatrix.length + i,
          countTop - this.groundMatrix.length + i + 1,
          this.bottomGeometry.vertices.length - this.groundMatrix.length + i,
        ),
        new THREE.Face3(
          countTop - this.groundMatrix.length + i + 1,
          this.bottomGeometry.vertices.length - this.groundMatrix.length + i,
          this.bottomGeometry.vertices.length -
            this.groundMatrix.length +
            i +
            1,
        ),
      )
    }

    this.bottomGeometry.faces.push(
      new THREE.Face3(0, this.groundMatrix.length, countTop),
      new THREE.Face3(
        this.groundMatrix.length,
        countTop,
        this.groundMatrix.length + countTop,
      ),
      new THREE.Face3(
        this.groundMatrix.length - 1,
        2 * this.groundMatrix.length - 1,
        this.groundMatrix.length + countTop - 1,
      ),
      new THREE.Face3(
        2 * this.groundMatrix.length - 1,
        this.groundMatrix.length + countTop - 1,
        this.groundMatrix.length + countTop + 1,
      ),
      new THREE.Face3(
        countTop - 2 * this.groundMatrix.length,
        countTop - this.groundMatrix.length,
        countTop +
          this.groundMatrix.length +
          2 * (this.groundMatrix.length - 3),
      ),
      new THREE.Face3(
        countTop - this.groundMatrix.length,
        countTop +
          this.groundMatrix.length +
          2 * (this.groundMatrix.length - 3),
        this.bottomGeometry.vertices.length - this.groundMatrix.length,
      ),
      new THREE.Face3(
        countTop - this.groundMatrix.length - 1,
        countTop - 1,
        this.bottomGeometry.vertices.length - this.groundMatrix.length - 1,
      ),
      new THREE.Face3(
        countTop - 1,
        this.bottomGeometry.vertices.length - this.groundMatrix.length - 1,
        this.bottomGeometry.vertices.length - 1,
      ),
    )

    let externalCount = 1
    for (
      let i = 2 * this.groundMatrix.length,
        count = countTop - this.groundMatrix.length;
      i < count;
      i += this.groundMatrix.length
    ) {
      this.bottomGeometry.faces.push(
        new THREE.Face3(
          i - 1,
          i + this.groundMatrix.length - 1,
          countTop + this.groundMatrix.length + 2 * externalCount - 1,
        ),
        new THREE.Face3(
          i + this.groundMatrix.length - 1,
          countTop + this.groundMatrix.length + 2 * externalCount - 1,
          countTop + this.groundMatrix.length + 2 * (externalCount + 1) - 1,
        ),
        new THREE.Face3(
          i - this.groundMatrix.length,
          i,
          countTop + this.groundMatrix.length + 2 * externalCount,
        ),
        new THREE.Face3(
          i - this.groundMatrix.length,
          countTop + this.groundMatrix.length + 2 * externalCount,
          countTop + this.groundMatrix.length + 2 * (externalCount - 1),
        ),
      )
      externalCount += 1
    }

    this.bottomGeometry.faces.push(
      new THREE.Face3(
        countTop,
        countTop + this.groundMatrix.length - 1,
        this.bottomGeometry.vertices.length - 1,
      ),
      new THREE.Face3(
        countTop,
        this.bottomGeometry.vertices.length - 1,
        this.bottomGeometry.vertices.length - this.groundMatrix.length,
      ),
    )
  }

  _getMinimalAndMaximalPoints() {
    let minimalHeight = 0
    let maximalHeight = 0

    for (let i = 0, count = this.groundMatrix.length; i < count; i++) {
      for (let j = 0, count2 = this.groundMatrix.length; j < count2; j++) {
        const vertex = this.groundMatrix[i][j]
        if (vertex[2] < minimalHeight) {
          minimalHeight = vertex[2]
          this.minimalPoint = vertex
        }
        if (vertex[2] > maximalHeight) {
          maximalHeight = vertex[2]
          this.maximalPoint = vertex
        }
      }
    }
    if (!this.minimalPoint) {
      this.minimalPoint = this.groundMatrix[0][0]
    }
    if (!this.maximalPoint) {
      this.maximalPoint = this.groundMatrix[0][0]
    }
  }
}

export default GroundHelper