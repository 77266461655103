import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Container, useMediaQuery, useTheme } from '@material-ui/core'
import { changeMapType, changeMapZoomType } from '@/redux/store'
import {
  Tools,
  ToolsWrapper,
  StyledTab,
  Separator,
  StyledTabs,
} from './style'
import SearchField from '../SearchField'
import { useWhichMapType } from '@/hooks/useWhichMapType'
import { changeMapCenterTrigger, changeMapZoom } from '../../redux/store'

const MapTypeTabs = ({ isMediumUp }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { mapType } = useSelector((state) => state.map)

  const handleChange = (_, newType) => {
    dispatch(changeMapType(newType))
    dispatch(changeMapZoom(newType === 200 ? 13 : 17))
  }

  return (
    <StyledTabs
      value={mapType}
      onChange={handleChange}
      indicatorColor='primary'
      size={isMediumUp ? 'medium' : 'small'}
    >
      <StyledTab label={t('mapTools:mapTypeRoadmap')} value='roadmap' />
      <StyledTab label={t('mapTools:mapTypeSatellite')} value='satellite' />
    </StyledTabs>
  )
}

const DimensionTabs = ({ isMediumUp }) => {
  const history = useHistory()
  const { lat, lng } = useSelector((state) => state.map.location)
  const [mapType] = useWhichMapType()

  const handleChange = (event, newValue) => {
    if (lng && lat) {
      if (newValue === '3d') {
        history.push(`/map/3d/${lat},${lng}`)
      } else {
        history.push(`/map/2d/${lat},${lng}`)
      }
    }
  }

  return (
    <StyledTabs
      value={mapType}
      onChange={handleChange}
      indicatorColor='primary'
      size={isMediumUp ? 'medium' : 'small'}
    >
      <StyledTab label='2D' value='2d' />
      <StyledTab label='3D' value='3d' />
    </StyledTabs>
  )
}

const SearchTabs = ({ searchType, setSearchType, isMediumUp }) => {
  const [t] = useTranslation()
  const handleChange = (_, newValue) => {
    setSearchType(newValue)
  }
  return (
    <StyledTabs
      value={searchType}
      onChange={handleChange}
      indicatorColor='primary'
      size={isMediumUp ? 'medium' : 'small'}
    >
      <StyledTab label={t('mapTools:mapSearchAddress')} value='address' />
      <StyledTab
        label={t('mapTools:mapSearchCoordinates')}
        value='coordinates'
      />
    </StyledTabs>
  )
}

const RadiusTabs = ({ mapZoomType, isMediumUp }) => {
  const dispatch = useDispatch()

  const handleChange = (newType) => {
    dispatch(changeMapZoomType(newType))
    dispatch(changeMapCenterTrigger())
  }

  return (
    <StyledTabs
      value={mapZoomType}
      indicatorColor='primary'
      size={isMediumUp ? 'medium' : 'small'}
    >
      <StyledTab label='200m' value={200} onClick={() => handleChange(200)} />
      <StyledTab label='5km' value={5000} onClick={() => handleChange(5000)} />
    </StyledTabs>
  )
}

const MapTools = () => {
  const [searchType, setSearchType] = useState('address')
  const [mapType] = useWhichMapType()
  const { lat, lng } = useSelector((state) => state.map.location)
  const { mapZoomType } = useSelector((state) => state.map)

  const theme = useTheme()
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Tools>
      <Container maxWidth='sm'>
        <Box py={1}>
          <ToolsWrapper showForMedium>
            <SearchTabs
              searchType={searchType}
              setSearchType={setSearchType}
              isMediumUp={isMediumUp}
            />
            {lat && lng && (
              <>
                <Separator />
                <DimensionTabs isMediumUp={isMediumUp} />
              </>
            )}
            {mapType === '2d' && (
              <>
                <Separator />
                <MapTypeTabs isMediumUp={isMediumUp} />
                {lat && lng && (
                  <>
                    <Separator />
                    <RadiusTabs isMediumUp={isMediumUp} mapZoomType={mapZoomType} />
                  </>
                )}
              </>
            )}
          </ToolsWrapper>
          <ToolsWrapper hideForMedium>
            <SearchTabs
              searchType={searchType}
              setSearchType={setSearchType}
              isMediumUp={isMediumUp}
            />
          </ToolsWrapper>
        </Box>
        <SearchField searchType={searchType} />
        <Box py={1}>
          <ToolsWrapper hideForMedium>
            {lat && lng && (
              <>
                <DimensionTabs isMediumUp={isMediumUp} />
              </>
            )}
            {mapType === '2d' && (
              <>
                {lat && lng && <Separator />}
                <MapTypeTabs isMediumUp={isMediumUp} />
                {lat && lng && (
                  <>
                    <Separator />
                    <RadiusTabs isMediumUp={isMediumUp} mapZoomType={mapZoomType} />
                  </>
                )}
              </>
            )}
          </ToolsWrapper>
        </Box>
      </Container>
    </Tools>
  )
}

export default MapTools
