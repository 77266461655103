import { configureStore, createSlice } from '@reduxjs/toolkit';
import { getMapDimensionTypeFromURL } from '@/helpers/routing';

const defaultLocationState = {
  lat: null,
  lng: null,
  municipality: null,
  canton: null,
  address: null,
  zoom: null,
};

const mapReducer = createSlice({
  name: 'map',
  initialState: {
    mapType: 'roadmap',
    mapZoomType: 200,
    mapZoom: 5,
    location: { ...defaultLocationState },
    mapCenterTrigger: false,
  },
  reducers: {
    changeMapType: (state, action) => {
      state.mapType = action.payload;
    },
    changeMapZoomType: (state, action) => {
      state.mapZoomType = action.payload;
      state.mapZoomTypeTrigger = !state.mapZoomTypeTrigger;
    },
    changeMapCenterTrigger: (state, action) => {
      state.mapCenterTrigger = !state.mapCenterTrigger;
    },
    changeMapZoom: (state, action) => {
      state.mapZoom = action.payload;
    },
    setLocation: (state, action) => {
      const { lat, lng, municipality, canton, address, zoom } = action.payload;
      state.location = {
        lng,
        lat,
        municipality,
        canton,
        address,
        zoom,
      };
      const type = getMapDimensionTypeFromURL(window.location);
      if (zoom) {
        window.history.pushState(null, null, `/map/${type}/${lat},${lng},${zoom}${window.location.search}`);
      } else {
        window.history.pushState(null, null, `/map/${type}/${lat},${lng}${window.location.search}`);
      }
    },
    resetMap: (state) => {
      state.mapType = 'roadmap';
      state.mapZoomType = 200;
      state.mapZoom = 4;
      state.location = { ...defaultLocationState };
    },
  },
});

export const { changeMapType, changeMapZoomType, changeMapCenterTrigger, changeMapZoom, setLocation, resetMap } = mapReducer.actions;

const userReducer = createSlice({
  name: 'user',
  initialState: {
    fname: '',
    lname: '',
    address: '',
    postal: '',
    city: '',
    email: '',
    company: '',
    agree: false,
    agreeNewsletter: true,
    voucher: '',
  },
  reducers: {
    updateUser: (state, action) => {
      state.fname = action.payload.fname;
      state.lname = action.payload.lname;
      state.address = action.payload.address;
      state.postal = action.payload.postal;
      state.city = action.payload.city;
      state.email = action.payload.email;
      state.agree = action.payload.agree;
      state.agreeNewsletter = action.payload.agreeNewsletter;
      state.company = action.payload.company;
    },
    changeVoucher: (state, action) => {
      state.voucher = action.payload.voucher;
    },
    changeEmail: (state, action) => {
      state.email = action.payload;
    },
  },
});

export const { changeEmail, changeVoucher, updateUser } = userReducer.actions;

export default configureStore({
  reducer: {
    map: mapReducer.reducer,
    user: userReducer.reducer,
  },
});
