import React, { useState, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import getSchema from './schema'
import {
  StyledFormControl,
  StyledTextField,
  StyledInputAdornment,
  StyledInputLabel,
} from './style'

function numberWithSeparator(num, separator = ' ') {
  if (!num) return num
  let parts = num.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator)
  return parts.join('.')
}

/**
 * Input fields let users enter and edit value.
 */
const Field = (props) => {
  const {
    autoComplete = 'off',
    className,
    formControlClassName,
    type = 'text',
    value,
    name,
    label,
    unit,
    disable,
    disabled,
    labelInside = false,
    changeHandler,
    debounce,
    debounceTime = 300,
    required = false,
    min = null,
    max = null,
    step = 1,
    size = 'small',
    precision = null,
    errors = [],
    fieldID,
    thousandSeparation = false,
    inputRef,
    startAdornment,
    endAdornment,
    inputProps,
    InputProps,
    onKeyUp,
    placeholder,
    width,
  } = props

  let initValue = value
  if (thousandSeparation && type !== 'number') {
    initValue = numberWithSeparator(value)
  }

  const [currentValue, setCurrentValue] = useState(initValue || '')
  const [fieldErrors, setFieldErrors] = useState([])

  // Add together field schema and prop errors
  const getErrors = () => [...(fieldErrors || []), ...(errors || [])]

  // Get the schema depending on field type (Ex. 'number' || 'text')
  const schema = getSchema(type)

  // Validate field and change
  const change = (value) => {
    schema
      .validate({ value }, { context: { min, max, required } })
      .then(() => {
        setFieldErrors([])
        changeHandler(value)
      })
      .catch((err) => {
        setFieldErrors(err.errors)
      })
  }

  // Debounce the change by specified milliseconds
  const debouncedChange = useDebouncedCallback(change, debounceTime)

  // Change field state value and call (and debounce) changeHandler
  const changeValues = (changeValue) => {
    if (!disable) {
      const value =
        precision !== null && precision >= 0
          ? roundValueToPrecision(changeValue)
          : changeValue
      setCurrentValue(value)
      if (debounce) {
        debouncedChange(value)
      } else {
        change(value)
      }
    }
  }

  // Round the value to given precision
  function roundValueToPrecision(val) {
    const multiplier = 10 ** precision
    const rounded = Math.round(Number(val) * multiplier) / multiplier
    return val ? rounded : val
  }

  // Round value to precision if a number
  const getCurrentValue = (value) => {
    return precision !== null && precision >= 0
      ? roundValueToPrecision(value)
      : value
  }

  // Update values if prop value changes
  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  // Get the end adornment
  const getEndAdornment = () => {
    if (unit) {
      return <StyledInputAdornment position='end'>{unit}</StyledInputAdornment>
    } else if (endAdornment) {
      return endAdornment
    } else {
      return null
    }
  }

  return (
    <StyledFormControl className={formControlClassName} labelInside={labelInside} width={width}>
      {label && <StyledInputLabel htmlFor={name}>{label}</StyledInputLabel>}
      <StyledTextField
        autoComplete={autoComplete}
        className={className}
        disabled={disabled}
        error={!!getErrors().length}
        helperText={!!getErrors().length && getErrors().join(', ')}
        inputRef={inputRef}
        inputProps={{
          min,
          max,
          step,
          id: fieldID || '',
          ...inputProps,
        }}
        InputProps={{
          startAdornment: startAdornment,
          endAdornment: getEndAdornment(),
          ...InputProps
        }}
        name={name}
        onChange={(e) => changeValues(e.target.value)}
        onKeyUp={onKeyUp}
        required={required}
        size={size}
        type={type}
        value={getCurrentValue(currentValue)}
        variant='outlined'
        placeholder={placeholder}
      />
    </StyledFormControl>
  )
}

export default Field
