import proj4 from 'proj4';

const wgsProj = '+proj=longlat +datum=WGS84 +no_defs';
const lvProj =
  '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs';

export const wgsToLV95 = ({ lng, lat }) => {
  const [x, y] = proj4(wgsProj, lvProj, [lng, lat]);
  return { x, y };
};

export const lv95ToWgs = ({ x, y }) => {
  const [lng, lat] = proj4(lvProj, wgsProj, [x, y]);
  return { lng, lat };
};
