import React from 'react'
import StyledTypography from './style'

const Typography = ({ children, fontWeight, ...props }) => (
  <StyledTypography {...props} fontWeight={fontWeight}>
    {children}
  </StyledTypography>
)

export default Typography
