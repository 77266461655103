import { Box, Container } from '@material-ui/core'

const PageLayout = ({ children, maxWidth = 'sm' }) => (
  <Container maxWidth={maxWidth}>
    <Box py={3}>
      {children}
    </Box>
  </Container>
)

export default PageLayout
