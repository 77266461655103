import { SvgIcon } from '@material-ui/core'

const Close = (props) => (
  <SvgIcon
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M15.6806 14.1375L9.54282 7.99975L15.6803 1.8627C15.879 1.65696 15.9889 1.38142 15.9865 1.0954C15.984 0.809387 15.8692 0.535792 15.667 0.333541C15.4647 0.131291 15.1911 0.016569 14.9051 0.0140836C14.619 0.0115983 14.3435 0.121549 14.1377 0.320254L7.99991 6.45731L1.86246 0.319526C1.76115 0.218224 1.64088 0.137867 1.50851 0.0830423C1.37614 0.0282179 1.23427 0 1.091 0C0.947729 0 0.80586 0.0282179 0.673493 0.0830423C0.541127 0.137867 0.420856 0.218224 0.319547 0.319526C0.114944 0.524116 0 0.801598 0 1.09093C0 1.23419 0.0282194 1.37605 0.0830473 1.50841C0.137875 1.64077 0.218238 1.76103 0.319547 1.86234L6.457 8.00012L0.31882 14.1379C0.12015 14.3437 0.0102567 14.6193 0.0128094 14.9053C0.0153622 15.1913 0.130157 15.4649 0.332468 15.6671C0.534779 15.8693 0.80842 15.9839 1.09445 15.9863C1.38049 15.9888 1.65603 15.8787 1.86173 15.68L7.99991 9.54256L14.1374 15.6803C14.342 15.885 14.6195 16 14.9089 16C15.1983 16 15.4758 15.8851 15.6805 15.6805C15.8851 15.4759 16.0001 15.1984 16.0001 14.9091C16.0002 14.6197 15.8852 14.3422 15.6806 14.1375Z' />
  </SvgIcon>
)

export default Close
