import styled, { css } from 'styled-components'
import { Typography } from '@material-ui/core'

const StyledTypography = styled(Typography)`
  ${({ fontWeight }) =>
    fontWeight &&
    css`
      font-weight: ${fontWeight} !important;
    `}

  ${({ theme, color }) =>
    color === 'textSecondary' &&
    css`
      color: ${theme.palette.grey[600]};
    `}
`

export default StyledTypography
