import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const { useLocation } = require('react-router-dom')

const useQuery = () => new URLSearchParams(useLocation().search)

export const useLanguageQueryParam = () => {
  const query = useQuery()
  const history = useHistory()
  const { i18n } = useTranslation()

  const { pathname } = history.location
  const { language } = i18n

  const queryParamLang = query.get('lang')

  const setLanguageParam = (lang) => {
    const { pathname } = history.location
    query.set('lang', lang)
    const search = query.toString()
    history.replace({
      pathname,
      search,
    })
  }

  useEffect(() => {
    if (queryParamLang && queryParamLang !== language) {
      i18n.changeLanguage(queryParamLang)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLanguageParam(language)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, pathname])

  return []
}

export default useQuery
