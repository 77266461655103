import {useState} from 'react'
import {useDispatch} from 'react-redux'
import Autosuggest from 'react-autosuggest'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {MenuItem, Paper} from '@material-ui/core'
import {changeMapZoomType, setLocation} from '@/redux/store'
import {SearchButton, StyledField, StyledPinIcon, StyledSearchIcon, StyledWrapper,} from './style'
import {fetchMunicipalityAndCanton} from '../Map/hooks'

const getSuggestions = async (value) => {
  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length

  const fetchSuggestions = async (value) =>
      fetch(`https://api3.geo.admin.ch/rest/services/api/SearchServer?sr=2056&searchText=${value}&lang=de&type=locations`);

  const suggestions = await fetchSuggestions(inputValue)
    .then((res) => res.json())
    .then(({ results }) =>
      results.reduce((acc, cur) => {
        acc.push(cur.attrs)
        return acc
      }, []),
    )
  return inputLength === 0 ? [] : suggestions.slice(0, 5)
}

const getSuggestionValue = (suggestion) => {
  const div = document.createElement('div')
  div.innerHTML = suggestion.label
  const text = div.textContent || div.innerText || ''
  return text
}

const renderSuggestionsContainer = (options) => {
  const { containerProps, children } = options
  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  )
}

const renderSuggestion = (
  suggestion,
  { query, isHighlighted },
  dispatch,
  setSuggestion,
  history,
) => {
  const createMarkup = () => {
    return { __html: suggestion.label }
  }
  if (isHighlighted) {
    setSuggestion(suggestion)
  }
  return (
    <MenuItem
      selected={isHighlighted}
      component='div'
      onClick={() => {
        onSuggestSelect(suggestion, dispatch, history)
      }}
    >
      <div dangerouslySetInnerHTML={createMarkup(query)} />
    </MenuItem>
  )
}

const renderInput = (inputProps) => {
  const { classes, ref, searchEnter, ...other } = inputProps

  return (
    <StyledField
      size='medium'
      InputProps={{
        inputRef: ref,
        ...other,
        startAdornment: <StyledPinIcon />,
        endAdornment: (
          <SearchButton type='submit' aria-label='search' onClick={searchEnter}>
            <StyledSearchIcon />
          </SearchButton>
        ),
      }}
    />
  )
}

const onSuggestSelect = async (suggestion, dispatch, history) => {
  if (suggestion?.lat) {
    suggestion.lng = suggestion.lon
    const { zoomlevel } = suggestion
    const location = await fetchMunicipalityAndCanton(suggestion)
    dispatch(
      setLocation({
        ...suggestion,
        ...location,
        zoom: zoomlevel > 10 ? 13 : zoomlevel + 3,
      })
    )
    dispatch(changeMapZoomType(5000))
  }
}

const AddressSearch = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [suggestion, setSuggestion] = useState(null)

  const handleChange = (event, { newValue }) => setValue(newValue)

  const handleSuggestionsFetchRequested = async ({ value }) =>
    setSuggestions(await getSuggestions(value))

  const selectSuggestion = () => {
    onSuggestSelect(suggestion ?? suggestions?.[0], dispatch, history)
  }

  return (
    <StyledWrapper>
      <Autosuggest
        renderInputComponent={renderInput}
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={(suggestion, props) =>
          renderSuggestion(suggestion, props, dispatch, setSuggestion, history)
        }
        inputProps={{
          placeholder: t('search:placeholder'),
          type: 'search',
          value,
          onChange: handleChange,
          searchEnter: () => selectSuggestion(),
          onKeyPress: (e) => {
            if (e.key === 'Enter') {
              selectSuggestion()
            }
          },
        }}
      />
    </StyledWrapper>
  )
}

export default AddressSearch
