import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { resetMap } from '@/redux/store'
import {
  StyledAppBar,
  LogoLink,
  Logo,
  Left,
  Center,
  Right,
  StyledAvatar,
} from './style'

import LogoImage from '@/assets/images/logo-white.svg'

const Profile = (props) => {
  const { initials, image = null } = props

  return <StyledAvatar src={image}>{initials}</StyledAvatar>
}

const AppBar = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    logo,
    left,
    center,
    right,
    auth,
    userInitials,
    userImage,
    centerLogo,
  } = props

  const toMapPage = () => {
    history.push('/map/2d')
    dispatch(resetMap())
  }

  return (
    <StyledAppBar centerLogo={centerLogo}>
      <Left>
        <LogoLink style={{ cursor: 'pointer' }} onClick={toMapPage}>
          <Logo src={logo || LogoImage} alt='Amenti' />
        </LogoLink>
        {left}
      </Left>
      <Center>{center}</Center>
      <Right>
        {right}
        {auth && <Profile initials={userInitials} image={userImage} />}
      </Right>
    </StyledAppBar>
  )
}

export default AppBar
