import { SearchWrapper } from './style'
import AddressSearch from './AddressSearch'
import CoordinateSearch from './CoordinateSearch'

const SearchField = ({ searchType }) => {
  let Search;
  switch (searchType) {
    case 'address':
      Search = AddressSearch
      break
    case 'coordinates':
      Search = CoordinateSearch
      break
    default:
      Search = AddressSearch
  }
  return (
    <SearchWrapper>
      <Search />
    </SearchWrapper>
  )
}

export default SearchField
