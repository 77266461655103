import { SvgIcon } from '@material-ui/core'

const Latitude = props => (
  <SvgIcon
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    {...props}
  >
    <path
      d="M7.255.899a8.891 8.891 0 000 17.202c-.579-.493-1.089-1.164-1.514-1.955C4.82 14.429 4.26 12.079 4.26 9.5c0-2.579.56-4.929 1.481-6.646.425-.791.935-1.462 1.514-1.955zM9.5 0A9.5 9.5 0 000 9.5 9.5 9.5 0 009.5 19 9.5 9.5 0 0019 9.5 9.5 9.5 0 009.5 0zm-.306.633C8.109.774 7.078 1.66 6.281 3.144 5.417 4.755 4.873 7.002 4.873 9.5c0 2.498.544 4.745 1.408 6.356.797 1.484 1.828 2.37 2.913 2.511V.633zm.612 17.734V.633c1.085.141 2.116 1.027 2.913 2.511.864 1.611 1.408 3.858 1.408 6.356 0 2.498-.544 4.745-1.408 6.356-.797 1.484-1.828 2.37-2.913 2.511zm1.939-.266c.579-.493 1.089-1.164 1.514-1.955.921-1.717 1.481-4.067 1.481-6.646 0-2.579-.56-4.929-1.481-6.646-.425-.791-.935-1.462-1.514-1.955a8.891 8.891 0 010 17.202z"
    />
  </SvgIcon>
)

export default Latitude
