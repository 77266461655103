import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const isNotLocal = !process.env.REACT_APP_API_MAIN_URL?.includes('localhost');
  const environment = process.env.REACT_APP_ENV;
  if (isNotLocal) {
    Sentry.init({
      dsn: 'https://c5a3d5eef7a54f72bb279f97802b39a0@o318900.ingest.sentry.io/5991434',
      integrations: [new Integrations.BrowserTracing()],
      environment,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.35,
    })
  }
}
