import styled, { css } from 'styled-components'
import { Box } from '@material-ui/core'
import Typography from '../Typography'
import ArrowDown from '../Icons/ArrowDown'

const AccordionArrow = styled(Box)`
  display: flex;
  position: absolute;
  top: 50%;
  right: 10px;
  transition: transform 200ms ease-out;
  transform: ${({ expanded }) =>
  expanded
    ? 'translateY(-50%) rotate(180deg)'
    : 'translateY(-50%) rotate(0)'};
`

const ArrowIcon = styled(ArrowDown)``

const StyledAccordion = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  border-top: none;
  background-color: ${({ theme }) => theme.palette.grey[100]};

  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
    border-top-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
    border-top: 1px solid ${({ theme }) => theme.palette.grey[200]};
  }

  &:last-child {
    border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
    border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
  }

  ${({ childAccordion }) =>
  childAccordion &&
  css`
      border: none;
      border-radius: 0;
      border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
      &:last-child {
        border-bottom: none;
      }
    `}
`

const AccordionHeadTitle = styled(Typography)`
  font-size: ${({ small }) => (small ? 13 : 14)}px;
  transition: color 200ms ease-out;
`

const AccordionHeadSubtitle = styled(Typography)`
  font-size: ${({ small }) => (small ? 13 : 14)}px;
`

const AccordionHeadDescription = styled(Typography)`
  font-size: 11px;
`

const StyledAccordionHead = styled(Box)`
  position: relative;
  min-height: ${({ small }) => (small ? 0 : '50px')};
  box-sizing: border-box;
  padding: ${({ small }) =>
  small ? '8px 40px 8px 35px' : '12px 40px 12px 60px'};
  cursor: ${({ noClose }) => (noClose ? 'auto' : 'pointer')};

  ${AccordionArrow} {
    svg {
      color: rgb(189, 189, 189);
      transition: color 200ms ease-in-out;
    }
  }

  &:hover {
    ${AccordionArrow} {
      svg {
        color: rgb(3, 11, 14);
      }
    }

    ${AccordionHeadTitle} {
      color: ${({ theme }) => theme.palette.grey[900]};
    }
  }

  ${({ icon, small }) =>
  icon
    ? css`
          padding: ${small ? '8px 40px 8px 35px' : '12px 40px 12px 40px'};
        `
    : css`
          padding: ${small ? '8px 40px 8px 12px' : '12px 40px 12px 12px'};
        `}
`

const StyledAccordionContent = styled(Box)`
  ${({ noPadding, small }) =>
  noPadding
    ? css`
          padding: 0;
        `
    : css`
          padding: ${({ small }) => (small ? '0 12px 8px' : '0 12px 10px')};
        `}
`

const IconWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: ${({ small }) => (small ? 35 : 40)}px;

  svg {
    width: ${({ small }) => (small ? 15 : 20)}px;
    height: ${({ small }) => (small ? 15 : 20)}px;
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.25;
    }
    100% {
      opacity: 1;
    }
  }

  ${({ isAnimating }) =>
  isAnimating &&
  css`
      animation: fadeAnimation 1000ms infinite;
    `}
`

export {
  AccordionArrow,
  ArrowIcon,
  StyledAccordionHead,
  StyledAccordionContent,
  StyledAccordion,
  IconWrapper,
  AccordionHeadTitle,
  AccordionHeadSubtitle,
  AccordionHeadDescription,
}
