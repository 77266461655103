import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    padding-bottom: 32.5%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 75%;
  }
`

const StyledImg = styled.img`
  overflow: hidden;
  cursor: default;
  opacity: ${({ shown }) => shown ? 1 : 0};
  transition: opacity 250ms ease-out;
`

export { Wrapper, StyledImg }
