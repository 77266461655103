import { bbox, bboxPolygon, buffer, point } from '@turf/turf'

export const getPolygonOverlay = ({ lng, lat }, meters) => {
  const center = point([Number(lng), Number(lat)])
  const boundingBox = bbox(buffer(center, meters, { units: 'meters' }))
  const poly = bboxPolygon(boundingBox)
  const paths = poly.geometry.coordinates[0].map(c => ({
    lng: c[0], lat: c[1],
  }))
  return paths
}

export const createSmallPolygon = ({ lng, lat, mapZoomType }) => {
  const paths = getPolygonOverlay({ lng, lat }, 100)

  return new window.google.maps.Polygon({
    paths,
    strokeColor: '#2EC079',
    strokeOpacity: 1,
    strokeWeight: 1,
    fillColor: '#80FFB7',
    fillOpacity: mapZoomType === 200 ? 0.15 : 0,
    clickable: false,
  })
}

export const createLargePolygon = ({ lng, lat, mapZoomType }) => {
  const paths = getPolygonOverlay({ lng, lat }, 2500)
  return new window.google.maps.Polygon({
    paths,
    strokeColor: '#2EC079',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#2EC079',
    fillOpacity: mapZoomType === 200 ? 0 : 0.15,
    clickable: false,
  })
}
