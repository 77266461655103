import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@material-ui/core'
import proj4 from 'proj4'
import {
  point,
  polygon,
  bbox,
  bboxPolygon,
  booleanWithin,
} from '@turf/turf'
import {
  setLocation,
  changeMapZoomType
} from '@/redux/store'
import {
  StyledField,
  SearchButton,
  StyledLongitudeIcon,
  StyledLatitudeIcon,
  StyledSearchIcon,
} from './style'
import { fetchMunicipalityAndCanton } from '../Map/hooks'

const wgsProj = '+proj=longlat +datum=WGS84 +no_defs'
const lv =
  '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs'

const SWITZERLAND_BBOX = [
  [5.855963134765625, 45.71922927350267],
  [5.855963134765625, 47.90848549672742],
  [10.592286682128906, 47.90848549672742],
  [10.592286682128906, 45.71922927350267],
  [5.855963134765625, 45.71922927350267],
]
const poly = polygon([SWITZERLAND_BBOX])
const swissBbox = bbox(poly)
const bboxPoly = bboxPolygon(swissBbox)

const CoordinateSearch = () => {
  const dispatch = useDispatch()

  const [x, setX] = useState()
  const [y, setY] = useState()

  const onLocationSelect = async () => {
    if (x && y) {
      const [lng, lat] = proj4(lv, wgsProj, [Number(x), Number(y)])
      const pt = point([
        Number(lng),
        Number(lat),
      ])
      const inCH = booleanWithin(pt, bboxPoly)
      if (inCH) {
        const location = await fetchMunicipalityAndCanton({ lng: Number(lng), lat: Number(lat) })
        dispatch(setLocation({
          lng: Number(lng),
          lat: Number(lat),
          ...location,
        }))
        dispatch(changeMapZoomType(5000))
      } else console.error('Outside of Switzerland!')
    } else console.error('Insert coordinates!')
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <StyledField
          size='medium'
          placeholder='x'
          value={x}
          changeHandler={v => setX(v)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onLocationSelect()
            }
          }}
          InputProps={{
            startAdornment: <StyledLatitudeIcon />,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledField
          size='medium'
          placeholder='y'
          value={y}
          changeHandler={v => setY(v)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onLocationSelect()
            }
          }}
          InputProps={{
            startAdornment: <StyledLongitudeIcon />,
            endAdornment: (
              <SearchButton type='submit' aria-label='search' onClick={onLocationSelect}>
                <StyledSearchIcon />
              </SearchButton>
            ),
          }}
        />
      </Grid>
    </Grid>
  )
}

export default CoordinateSearch
