import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Lightbox from 'react-datatrans-light-box'
import superagent from 'superagent'
import prefix from 'superagent-prefix'
import { Box, Chip, CircularProgress, Grid, Tooltip } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import styled from 'styled-components'
import proj4 from 'proj4'
import PageLayout from '../components/PageLayout'
import Button from '../components/Button'
import BackButton from '../components/BackButton'
import Typography from '../components/Typography'
import Separator from '../components/Separator'
import Field from '../components/Field'
import TagManager from 'react-gtm-module'
import { changeVoucher } from '../redux/store'

const RedeemButton = styled(Button)`
  color: ${({theme}) => theme.palette.grey[500]};
  font-size: 13px;
  padding: 0;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    background-color: transparent;
  }
`

const RedeemSubmitButton = styled(Button)`
  padding: 4px 20px;
`

const PayButton = styled(Button)`
  width: 100%;
`
const wgsProj = '+proj=longlat +datum=WGS84 +no_defs'
const lv =
  '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs'

const PaymentRoute = () => {
  const {
    fname,
    lname,
    email,
    address: userAddress,
    postal,
    city,
    company,
    agreeNewsletter,
    voucher: userVoucher,
  } = useSelector((state) => state.user)
  const [t] = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [redeemOpen, setRedeemOpen] = useState(false)
  const [voucher, setVoucher] = useState(userVoucher || '')
  const [lightbox, showLightbox] = useState(false)
  const [transactionId, setTransactionId] = useState('')
  const {i18n} = useTranslation()

  const {municipality, canton, address, lat, lng} = useSelector((state) => state.map.location)
  const [x, y] = proj4(wgsProj, lv, [Number(lng), Number(lat)])

  const [cart, setCart] = useState({
    productPrice: 0,
    userPrice: {
      priceWithoutVat: 0,
      vat: 0,
      priceWithVat: 0,
    }
  })

  const getPrices = async (code) => {
    let url = '/api/data-app/TerrainExportZip/price'
    if (code) {
      url += `?voucher=${code}`
    }
    const response = await superagent.get(url).use(prefix(process.env.REACT_APP_API_MAIN_URL))
    return response.body
  }

  const voucherHandler = (value) => {
    setVoucher(value)
  }

  const redeemVoucher = () => {
    updateCart(voucher)
  }

  const removeDiscount = () => {
    setVoucher('')
    dispatch(changeVoucher({voucher: ''}))
    updateCart()
  }

  const updateCart = async (code) => {
    setLoading(true)
    const data = await getPrices(code)
    if (!data.discount?.error) {
      setRedeemOpen(false)
      dispatch(changeVoucher({voucher: code}))
    }
    setCart(data)
    setLoading(false)
  }

  const toPrevPage = (replace) => {
    replace ? history.replace('/site') : history.push('/site')
  }

  const toSuccessPage = (transactionId) => {
    history.push(`/payment/success?datatransTrxId=${transactionId}`)
  }

  useEffect(() => {
    updateCart(voucher);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Redirect to previous page if email not set
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => !email && toPrevPage(true), [])

  const startPayment = async () => {
    let url = '/api/data-app/TerrainExportZip/initialize'
    const {language} = i18n

    superagent
    .post(url)
    .send({
      voucher,
      marker: {lng, lat},
      lvMarker: {x, y},
      language,
      user: {
        email,
        fname,
        lname,
        userAddress,
        postal,
        city,
        company,
        agreeNewsletter,
      },
    })
    .use(prefix(process.env.REACT_APP_API_MAIN_URL))
    .then((res) => {
      const {transactionId, fullDiscount} = res.body
      if (fullDiscount) {
        toSuccessPage(transactionId)
      } else {
        setTransactionId('' + transactionId)
        showLightbox(true)
      }
    })
    .catch((err) => {
      console.error(err)
    })
  }

  const onPaymentError = (data) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'payment_failed',
      },
    })
    showLightbox(false)
  }

  const onPaymentCancel = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'payment_canceled',
      },
    })
    showLightbox(false)
  }

  return (
    <PageLayout maxWidth='xs'>
      <Box mb={2}>
        <BackButton onClick={toPrevPage}>{t('paymentRoute:plotInfo')}</BackButton>
      </Box>
      <Box mb={2}>
        <Box mb={2}>
          <Typography component='h1' variant='h3' fontWeight={500}>
            {t('paymentRoute:payment')}
          </Typography>
        </Box>
        <Box mb={2}>
          {address ? (
            <Box mb={1}>
              <Typography variant='body1' color='textSecondary'>
                {address}
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                {municipality}, {canton}
              </Typography>
            </Box>
          ) : (
            <Typography variant='body1' color='textSecondary'>
              {municipality}, {canton}
            </Typography>
          )}
          <Typography variant='body1' color='textSecondary'>
            {t('paymentRoute:dataSent')} {email}
          </Typography>
        </Box>
        <Box my={2}>
          <Separator/>
        </Box>
        <Grid container>
          <Grid item xs={6}>
            <Typography color='textSecondary' variant='body1'>
              {t('paymentRoute:plotCost')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box display='flex' justifyContent='flex-end'>
              {loading ? (
                <Skeleton width={60} height={25}/>
              ) : (
                <Typography color='textPrimary' variant='body1' fontWeight={500}>
                  {cart.productPrice / 100} CHF
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box my={2}>
              <Separator/>
            </Box>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {redeemOpen ? (
                <Box mb={2}>
                  <Field
                    errors={cart.discount?.error && ['Discount code not valid']}
                    width='100%'
                    size='medium'
                    placeholder={t('paymentRoute:discountCode')}
                    value={voucher}
                    changeHandler={(value) => voucherHandler(value)}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter' && !!voucher.length) {
                        redeemVoucher()
                      }
                    }}
                    type='email'
                    InputProps={{
                      endAdornment: !!voucher.length ? (
                        <RedeemSubmitButton onClick={redeemVoucher} size='small' color='primary' variant='contained'>
                          {t('paymentRoute:apply')}
                        </RedeemSubmitButton>
                      ) : (
                        <Tooltip title='Enter a discount code' arrow placement='top'>
                          <Box display='inline-block'>
                            <RedeemSubmitButton size='small' color='primary' variant='contained' disabled={true}>
                              {t('paymentRoute:redeem')}
                            </RedeemSubmitButton>
                          </Box>
                        </Tooltip>
                      ),
                    }}
                  />
                </Box>
              ) : (
                !cart.discount &&
                !loading && (
                  <Box mb={2}>
                    <RedeemButton variant='text' onClick={() => setRedeemOpen(true)}>
                      <Typography color='textSecondary' variant='body1'>
                        {t('paymentRoute:discount')}
                      </Typography>
                    </RedeemButton>
                  </Box>
                )
              )}
              {cart.discount?.title && (
                <>
                  <Box display='flex' alignItems='center' justifyContent='space-between' mb={1}>
                    <Box display='flex' alignItems='center'>
                      <Typography color='textSecondary' variant='body1'>
                        Discount
                      </Typography>
                      <Box ml={1}>
                        <Chip label={cart.discount.title} color='secondary' size='small' onDelete={removeDiscount}/>
                      </Box>
                    </Box>
                    <Box ml={2}>
                      <Box display='flex' justifyContent='flex-end'>
                        {loading ? (
                          <Skeleton width={60} height={25}/>
                        ) : (
                          <Typography color='textPrimary' variant='body1' fontWeight={500}>
                            -{cart.discount.saved / 100} CHF
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
              {!loading && (
                <Box my={2}>
                  <Separator/>
                </Box>
              )}
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Typography color='textSecondary' variant='body1'>
                  {t('paymentRoute:subtotal')}
                </Typography>
                <Box ml={2}>
                  {loading ? (
                    <Skeleton width={60} height={25}/>
                  ) : (
                    <Typography color='textPrimary' variant='body1' component='body1' fontWeight={500}>
                      {cart.userPrice.priceWithoutVat / 100} CHF
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Typography color='textSecondary' variant='body1'>
                  {t('paymentRoute:vat')}
                </Typography>
                <Box ml={2}>
                  {loading ? (
                    <Skeleton width={60} height={25}/>
                  ) : (
                    <Typography color='textPrimary' variant='body1' component='body1' fontWeight={500}>
                      {cart.userPrice.vat / 100} CHF
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box my={2}>
                <Separator/>
              </Box>
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Typography color='textSecondary' variant='body1'>
                  {t('paymentRoute:total')}
                </Typography>
                <Box ml={2}>
                  {loading ? (
                    <Skeleton width={60} height={25}/>
                  ) : (
                    <Typography color='textPrimary' variant='body1' component='body1' fontWeight={500}>
                      {cart.userPrice.priceWithVat / 100} CHF
                    </Typography>
                  )}
                </Box>
              </Box>
              {!loading && (
                <Box mt={4} textAlign='center'>
                  {lightbox && (
                    <Lightbox
                      transactionId={transactionId}
                      production={process.env.REACT_APP_ENV === 'production'}
                      onCancelled={onPaymentCancel}
                      onError={onPaymentError}
                    />
                  )}
                  <PayButton
                    onClick={() => {
                      TagManager.dataLayer({
                        dataLayer: {
                          event: 'payment_initalized',
                          voucherCode: voucher,
                        },
                      })
                      startPayment()
                    }}
                    size='large'
                    color='primary'
                    variant='contained'
                    disabled={lightbox}
                  >
                    {lightbox ?
                      <CircularProgress size={20} color='rgba(0, 0, 0, 0.26)'/> : <>{t('paymentRoute:pay')}</>}
                  </PayButton>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  )
}
export default PaymentRoute
