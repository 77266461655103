import styled from 'styled-components'
import Field from '../Field'
import Pin from '../Icons/Pin'
import Search from '../Icons/Search'
import Button from '../Button'
import { IconButton } from '@material-ui/core'
import { shadows } from '../../theme/theme'
import Longitude from '../Icons/Longitude'
import Latitude from '../Icons/Latitude'
import Close from '../Icons/Close'

const Map = styled.div`
  width: 100%;
  height: 100%;
`

const MapRouteContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100vh - 50px);
  height: calc(var(--app-height) - 50px);
`

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const SearchWrapper = styled.div`
  .MuiFormControl-root {
    width: 100%;
  }
`

const StyledField = styled(Field)`  
  .MuiInputBase-root {
    background-color: ${({ theme }) => theme.palette.grey[900]};
    border-radius: 100px;
    padding: 0 6px 0 8px;

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.grey[700]};
      }
    }
    
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.grey[600]};
      }
    }
  }

  .MuiInputBase-input {
    padding: 8px;
    color: ${({ theme }) => theme.palette.grey[100]};
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.grey[800]};
  }
`

const StyledPinIcon = styled(Pin)`
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 18px;
`

const StyledLongitudeIcon = styled(Longitude)`
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 18px;
`

const StyledLatitudeIcon = styled(Latitude)`
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 18px;
`

const SearchButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  min-width: 0;
  
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }
`

const StyledSearchIcon = styled(Search)`
  color: ${({ theme }) => theme.palette.grey[700]};
  font-size: 14px;
`

const MapContinueButton = styled(Button)`
  width: 100%;
`

const ClearButton = styled(Close)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 10px;
  cursor: pointer;
`

const ContinueWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 9;
  bottom: 0;
  left: 50%;
  width: 100vw;
  transform: translateX(-50%);
  padding: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-top: 1px solid ${({ theme }) => theme.palette.grey[200]};
  box-shadow: ${shadows.medium};

  ${props => props.theme.breakpoints.up('sm')} {
    max-width: 400px;
    border: 1px solid ${({ theme }) => theme.palette.grey[200]};
    bottom: 30px;
    border-radius: 25px;
  }
`

const StyledWrapper = styled.div`
  position: relative;
  
  .react-autosuggest {
    &__suggestions-container {
      display: none;
      position: relative;
      z-index: 999;
      width: 100%;
      border: 1px solid ${({theme}) => theme.palette.grey[200]};
      border-radius: ${({theme}) => theme.shape.borderRadius}px;
      margin-top: ${({theme}) => theme.spacing(1)}px;
      box-shadow: ${shadows.medium};
      
      &--open {
        display: block;
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
      }
    }
    
    &__suggestions-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    
    &__suggestion {}
  }
`

export {
  Map,
  MapRouteContainer,
  MapWrapper,
  SearchWrapper,
  StyledField,
  StyledPinIcon,
  StyledLongitudeIcon,
  StyledLatitudeIcon,
  SearchButton,
  StyledSearchIcon,
  MapContinueButton,
  ContinueWrapper,
  StyledWrapper,
  ClearButton,
}
