import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import BackButton from '../components/BackButton'
import Typography from '../components/Typography'
import PageLayout from '../components/PageLayout'
import Accordion, { AccordionsWrapper } from '../components/Accordion'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.grey[600]};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

const FaqRoute = () => {
  const [t] = useTranslation()
  const history = useHistory()

  const toPrevPage = () => {
    if (history.action !== 'POP') {
      history.goBack()
    }
  }

  const toMapPage = () => {
    history.push('/map/2d')
  }

  return (
    <PageLayout>
      <Box mb={2}>
        {history.action === 'POP' ? (
          <BackButton onClick={toMapPage}>{t('faqRoute:explore')}</BackButton>
        ) : (
          <BackButton onClick={toPrevPage}>{t('faqRoute:back')}</BackButton>
        )}
      </Box>
      <Box mb={2}>
        <Typography component='h1' variant='h3' fontWeight={500}>
          {t('pages:faq')}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography color='textSecondary' variant='body2'>
          <AccordionsWrapper>
            <Accordion title={t('faq:archiCadImport:title')}>
              <StyledLink to={`${process.env.PUBLIC_URL}/pdf/Handbuch_Datenimport_in_ArchiCAD_24.pdf`}
                          target='_blank'>{t('faq:archiCadImport:text')}</StyledLink>
            </Accordion>
          </AccordionsWrapper>
        </Typography>
      </Box>
    </PageLayout>
  )
}

export default FaqRoute
