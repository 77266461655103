import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { setLocation } from '@/redux/store'

export const useInitialLocationSetter = ({ match }) => {
  const dispatch = useDispatch()
  const { lat: reduxLat, lng: reduxLng } = useSelector(
    (state) => state.map.location,
  )
  useEffect(() => {
    if (!reduxLat && !reduxLng) {
      let { lat, lng, zoom } = match.params
      lat = Number(lat)
      lng = Number(lng)
      zoom = Number(zoom)
      if (lat && lng) {
        dispatch(setLocation({ lat, lng, zoom }))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
