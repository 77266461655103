import styled, { css } from 'styled-components'
import { Tab, Tabs } from '../Tabs'
import Field from '../Field'
import Pin from '../Icons/Pin'
import Search from '../Icons/Search'
import Button from '../Button'
import { Box, CircularProgress, IconButton, Link } from '@material-ui/core'
import { shadows } from '../../theme/theme'
import { motion } from 'framer-motion'

const Map = styled.div`
  width: 100%;
  height: 100%;
`

const MapRouteContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: calc(100vh - 40px);
  height: calc(var(--app-height) - 40px);
  background-color: #1E2528;
`

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Tools = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.common.black};
  border-top: 1px solid ${({ theme }) => theme.palette.grey[900]};
`

const ToolsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${({ showForMedium, theme }) => showForMedium && css`
    display: none;

    ${theme.breakpoints.up('md')} {
      display: flex;
    }
  `}

  ${({ hideForMedium, theme }) => hideForMedium && css`
    ${theme.breakpoints.up('md')} {
      display: none;
    }
  `}
`

const StyledTabs = styled(Tabs)`
  width: 100%;
  
  ${props => props.theme.breakpoints.up('sm')} {
    width: auto;
  }
  
  .MuiTabs-flexContainer {
    justify-content: space-evenly;
  }
`

const StyledTab = styled(Tab)`
  color: ${({ theme }) => theme.palette.grey[500]};

  &:hover {
    &:not(.Mui-selected) {
      color: ${({ theme }) => theme.palette.grey[300]};
    }
  }

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.common.white};
  }
`

const SearchWrapper = styled.div`
  .MuiFormControl-root {
    width: 100%;
  }
`

const StyledField = styled(Field)`  
  .MuiInputBase-root {
    background-color: ${({ theme }) => theme.palette.grey[900]};
    border-radius: 100px;
    padding-right: 4px;

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.grey[700]};
      }
    }
    
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.grey[600]};
      }
    }
  }

  .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.grey[100]};

    &::placeholder {
      color: ${({ theme }) => theme.palette.grey[400]};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.grey[800]};
  }
`

const Separator = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${({ theme }) => theme.palette.grey[700]};
  margin: ${({ theme }) => `0 ${theme.spacing(2)}px`};
`

const StyledPinIcon = styled(Pin)`
  color: ${({ theme }) => theme.palette.grey[700]};
  font-size: 18px;
`

const SearchButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  min-width: 0;
  
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }
`

const StyledSearchIcon = styled(Search)`
  color: ${({ theme }) => theme.palette.grey[700]};
  font-size: 14px;
`

const MapContinueButton = styled(Button)`
  width: 100%;
  font-size: 12px;

  ${({theme}) => theme.breakpoints.up('sm')} {
    font-size: 14px;
  }

  ${({theme}) => theme.breakpoints.up('md')} {
    font-size: 15px;
  }
`

const ContinueWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 9;
  left: 50%;
  width: calc(100vw - 20px);
  bottom: 30px;
  transform: translateX(-50%);
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-top: 1px solid ${({ theme }) => theme.palette.grey[200]};
  box-shadow: ${shadows.medium};
  border-radius: 25px;
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};

  ${props => props.theme.breakpoints.up('sm')} {
    bottom: 30px;
    max-width: 400px;
  }
`

const LoadingView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1E2528;
  height: 100%;
  width: 100%;
`

const LoadingProgress = styled(CircularProgress)`
  margin-top: -160px;
`

const SwissTopo = styled(Link)`
  position: absolute;
  z-index: 9;
  bottom: 3px;
  left: ${({ mapType }) => mapType === '2d' ? '80px' : '8px'};
  font-size: 14px;
  color: ${({theme}) => theme.palette.common.white};
  font-weight: 500;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
`

const StyledTabsBox = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: auto;
  }
  
  button {
    font-size: ${({size}) => size === 'small' ? '12px' : '14px'};
    opacity: 1;
  }
`

export {
  Map,
  MapRouteContainer,
  MapWrapper,
  Tools,
  ToolsWrapper,
  StyledTabs,
  StyledTab,
  Separator,
  SearchWrapper,
  StyledField,
  StyledPinIcon,
  SearchButton,
  StyledSearchIcon,
  MapContinueButton,
  ContinueWrapper,
  LoadingView,
  LoadingProgress,
  SwissTopo,
  StyledTabsBox
}
