import { point, distance } from '@turf/turf';
import { wgsToLV95 } from '@/helpers/coordinateHelper';

const findExistingNeighbourValue = (values, width, idx, midElevation) => {
  // one up/down, always exists
  const neighbourIdxs = [idx - width, idx + width];
  // add left/right or both
  if (idx % width === 0) neighbourIdxs.push(idx + 1);
  else if (idx % width === 1) neighbourIdxs.push(idx - 1);
  else neighbourIdxs.push(...[idx - 1, idx + 1]);

  const neighbourVals = neighbourIdxs
  .filter((val) => val >= 0 && val < values.length)
  .map((i) => values[i])
  .filter((val) => val !== -9999);
  if (neighbourVals.length) {
    return neighbourVals[0] - midElevation;
  } else {
    let index = idx;
    // move towards the center with index
    if (idx < values.length / 2) index += width;
    else index -= width;
    if (idx % width < width / 2) index += 1;
    else index -= 1;
    return findExistingNeighbourValue(values, width, index, midElevation);
  }
};

export const callPostGisTerrain = async (lng, lat) => {
  const url = `${process.env.REACT_APP_PG_FEATURESERV_URL}/functions/elevation_by_width/items.json?lng=${lng}&lat=${lat}&w=100`;
  const binStr = await fetch(url)
  .then((res) => (res.ok ? res.json() : null))
  .then((res) => res?.[0]?.img ?? null);
  const tifUrl = `data:image/GeoTIFF;base64,${binStr}`;
  const blob = await fetch(tifUrl).then((res) => res.blob());
  const tiff = await window.GeoTIFF.fromBlob(blob);
  const data = await tiff.readRasters();
  let values = data[0];
  const { width, height } = data;
  const midElevation = values[parseInt(values.length / 2)];
  const matrix = [];
  let row = [];
  let x = -(width - 1);
  let y = height - 1;
  values.forEach((val, idx) => {
    const z =
      val === -9999
        ? findExistingNeighbourValue(values, width, idx, midElevation)
        : val - midElevation;
    row.unshift([x, y, z]);
    x += 2;
    if (idx % width === width - 1) {
      matrix.push([...row]);
      row = [];
      x = -(width - 1);
      y -= 2;
    }
  });
  return {
    elevation: matrix,
    midpointElevation: midElevation,
  };
};

export async function fetchBuildingsByRadius({ lng, lat }) {
  return await fetch(
    `${process.env.REACT_APP_PG_FEATURESERV_URL}/functions/buildings_by_radius/items.json?lng=${lng}&lat=${lat}&r=150&limit=500`,
  )
  .then((response) => (response.ok ? response.json() : null))
  .then((res) => (res ? res.features : []));
}

export const fetchMunicipalityAndCanton = async ({ lng, lat }) => {
  const location = await fetch(
    `${process.env.REACT_APP_PG_FEATURESERV_URL}/functions/municipality_and_canton_by_point/items.json?lng=${lng}&lat=${lat}`,
  )
  .then((res) => (res.ok ? res.json() : null))
  .then((res) => res?.[0] ?? null);
  const address = await searchForAddress({ lng, lat });
  return {
    ...location,
    address,
  };
};

const searchForAddress = async ({ lng, lat }) => {
  const { x, y } = wgsToLV95({ lng, lat });
  const url = `https://api3.geo.admin.ch/rest/services/api/MapServer/identify?mapExtent=0,0,100,100&imageDisplay=100,100,100&tolerance=50&sr=2056&geometryType=esriGeometryPoint&geometry=${x},${y}&layers=all:ch.bfs.gebaeude_wohnungs_register&returnGeometry=true`;
  const results = await fetch(url)
  .then((response) => (response.ok ? response.json() : null))
  .then((res) => res?.results ?? null);
  let d = Infinity;
  let address = '';
  const from = point([x, y]);
  results.forEach((res) => {
    const { x, y } = res.geometry;
    const to = point([x, y]);
    const options = { units: 'meters' };
    const dist = distance(from, to, options);
    if (dist < d) {
      d = dist;
      address = `${res.attributes.label}, ${res.attributes.dplz4} ${res.attributes.dplzname}`;
    }
  });
  return address;
};
