import styled, { css } from 'styled-components'
import { ErrorMessage, Field } from 'formik'


const StyledLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 12px;
  padding: 6px 10px 2px;
  color: ${({ theme }) => theme.palette.grey[700]};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
`

const Wrapper = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  transition: border 150ms ease-out;

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  }

  ${({ error }) => error && css`
    color: ${({ theme }) => theme.palette.error.main};
    border: 1px solid ${({ theme }) => theme.palette.error.main};
    outline: 0;
    box-shadow: none;

    &::placeholder {
      color: ${({ theme }) => theme.palette.error.main};
    }
    
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.error.main};
    }
    
    ${StyledLabel} {
      color: ${({ theme }) => theme.palette.error.main};
    }
  `}
`

const StyledField = styled(Field)`
  width: 100%;
  padding: 30px 10px 8px;
  background-color: transparent;
  box-shadow: none;
  color: ${({ theme }) => theme.palette.grey[900]};
  border: none;
  font-size: 16px;
  border-radius: 0;

  &::placeholder {
    color: ${({ theme }) => theme.palette.grey[300]};
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
`

const StyledError = styled(ErrorMessage)`
  display: inline-block;
  text-align: left;
  margin: 0 0 0 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.error.main};
  
  &:before {
    content: '(';
  }

  &:after {
    content: ')';
  }
`

export { Wrapper, StyledField, StyledError, StyledLabel }
