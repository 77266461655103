import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GoogleMapReact from 'google-map-react'
import superagent from 'superagent'
import { Map } from './style'
import mapStyle from './mapStyle'
import { createSmallPolygon, createLargePolygon } from './2D'
import { changeMapZoomType, setLocation, changeMapZoom } from '@/redux/store'
import { fetchMunicipalityAndCanton } from './hooks'
import { useInitialLocationSetter } from '@/hooks/useInitialLocationSetter'
import chBorder from './2D/ch.geojson'
import { CircularProgress, Snackbar } from '@material-ui/core'
import styled from 'styled-components'
import { transitions, shadows } from '@/theme/theme'
import markerImage from '@/assets/images/marker.svg'
import { useInitialVoucherSetter } from '../../hooks/useInitialVoucherSetter'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'

const SWITZERLAND_BOUNDS = {
  north: 47.90848549672742,
  south: 45.71922927350267,
  west: 5.855963134765625,
  east: 10.592286682128906,
}

const Spinner = styled.div`
  display: flex;
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.common.white};
  opacity: ${({ loading }) => (loading ? 1 : 0)};
  transition: opacity ${transitions.easing.easeInOut} ${transitions.duration.short}ms;
  box-shadow: ${shadows.medium};
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  pointer-events: none;
`

const getMapTypeId = (map, type) => {
  switch (type) {
    case 'satellite':
      return map.MapTypeId.HYBRID
    case 'roadmap':
      // return map.MapTypeId.ROADMAP
      return map.MapTypeId.TERRAIN
    default:
      throw new Error(type + ' is not a valid type for AmentiMap')
  }
}

const Marker = () => (
  <img
    width='30'
    height='30'
    src={markerImage}
    alt=''
    style={{ transform: 'translate(-50%, -100%)' }}
  />
)

const Map2D = ({ match }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const initialVoucher = useInitialVoucherSetter({ match })
  useInitialLocationSetter({ match })

  const [loading, setLoading] = useState(false)
  const [isClickEventOnGoing, setIsClickEventOnGoing] = useState(false)

  const [closeVoucherMessage, setCloseVoucherMessage] = React.useState(false)

  const handleMessageClose = (event, reason) => {
    setCloseVoucherMessage(true)
  }

  const { lat, lng } = useSelector((state) => state.map.location)
  const { mapType, mapZoomType, mapCenterTrigger, mapZoom } = useSelector(
    (state) => state.map,
  )

  const [map, setMap] = useState(null)
  const [smallPoly, setSmallPoly] = useState(null)
  const [largePoly, setLargePoly] = useState(null)

  function centerMap() {
    if (!isClickEventOnGoing) {
      if (mapZoomType === 200) {
        if (map.getZoom() !== 17) map.setZoom(17)
      } else if (mapZoomType === 5000) {
        if (map.getZoom() !== 13) map.setZoom(13)
      }
    }
    map.panTo({
      lng: Number(lng),
      lat: Number(lat),
    })
  }

  useEffect(() => {
    map && lng && lat ? centerMap() : dispatch(changeMapZoomType(5000))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapZoomType, mapCenterTrigger])

  useEffect(() => {
    if (map && lng && lat) {
      if (smallPoly) smallPoly.setMap(null)
      if (largePoly) largePoly.setMap(null)
      const lgPoly = createLargePolygon({ lng, lat, mapZoomType })
      const smPoly = createSmallPolygon({ lng, lat, mapZoomType })
      lgPoly.setMap(map)
      smPoly.setMap(map)
      setLargePoly(lgPoly)
      setSmallPoly(smPoly)

      centerMap()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, lng, lat, mapZoomType])

  const isInSwitzerland = async (center) => {
    const response = await superagent.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${center.lat},${center.lng}&key=${process.env.REACT_APP_GOOGLEMAP_API_KEY}`,
    )
    const { results } = response.body
    let isSwitzerland = false
    results.forEach((res) => {
      if (res.types.includes('country')) {
        if (res.address_components?.[0]?.short_name === 'CH' || res.address_components?.[0]?.short_name === 'LI')
          isSwitzerland = true
      }
    })
    return isSwitzerland
  }

  return (
    <Map>
      <Spinner loading={loading}>
        <CircularProgress size={20} />
      </Spinner>
      <Snackbar open={!!initialVoucher && !closeVoucherMessage} autoHideDuration={5000} onClose={handleMessageClose}>
        <Alert onClose={handleMessageClose} severity='success'>
          {t('voucherSuccess', { voucher: initialVoucher })}
        </Alert>
      </Snackbar>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLEMAP_API_KEY }}
        defaultCenter={{
          lng: Number(lng) || 8.2015,
          lat: Number(lat) || 46.8965,
        }}
        defaultZoom={5}
        zoom={Number(mapZoom)}
        options={(map) => {
          return {
            styles: mapStyle,
            minZoom: 4,
            maxZoom: 19,
            restriction: {
              latLngBounds: SWITZERLAND_BOUNDS,
              strictBounds: false,
            },
            fullscreenControl: false,
            zoomControl: true,
            zoomControlOptions: {
              position: map.ControlPosition.TOP_RIGHT,
            },
            rotateControl: false,
            mapTypeControl: false,
            draggableCursor: 'default',
            draggingCursor: 'move',
            clickableIcons: false,
            gestureHandling: 'greedy',
            mapTypeId: getMapTypeId(map, mapType),
          }
        }}
        onGoogleApiLoaded={({ map, maps }) => {
          setMap(map)
          map.setTilt(0)
          map.addListener('zoom_changed', () => {
            dispatch(changeMapZoom(map.getZoom()))
          })
          maps.event.addListener(map, 'click', async (data) => {
            setIsClickEventOnGoing(true)
            setLoading(true)
            const latLng = data.latLng
            const newCenter = {
              lng: latLng.lng(),
              lat: latLng.lat(),
            }
            const isCH = await isInSwitzerland(newCenter)
            if (isCH) {
              try {
                const location = await fetchMunicipalityAndCanton(newCenter)
                await dispatch(
                  setLocation({
                    ...newCenter,
                    ...location,
                  }),
                )
                setIsClickEventOnGoing(false)
                setLoading(false)
              } catch (e) {
                console.error(e)
                setLoading(false)
              }
            } else {
              setLoading(false)
            }
          })
          map.data.loadGeoJson(chBorder)
          map.data.setStyle({
            fillColor: '#1E2528',
            fillOpacity: 1,
            strokeWeight: 1,
            strokeOpacity: 0,
          })
        }}
      >
        {lng && lat && <Marker lat={Number(lat)} lng={Number(lng)} />}
      </GoogleMapReact>
    </Map>
  )
}

export default Map2D
